// import {
//   Box,
//   FormControl,
//   FormLabel,
//   Heading,
//   Input,
//   Grid,
//   Textarea,
//   Button,
//   Menu,
//   MenuButton,
//   MenuList,
//   MenuItem,
// } from "@chakra-ui/react";
// import React, { useState } from "react";
// import Navbar from "./Sidebar/Navbar";
// import Select from "react-select";
// import countryList from "react-select-country-list";
// import { IoChevronDownOutline } from "react-icons/io5";

// const AddNewCustomers = ({ isSidebarExpanded }) => {
//   const options = countryList().getData();
//   const [value, setValue] = React.useState(null);
//   const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
//   const leftMargin = isSidebarExpanded ? "270px" : "110px";

//   const [searchInput, setSearchInput] = useState("");
//   const [selectedExecutive, setSelectedExecutive] = useState("");
//   const [selectedStage, setSelectedStage] = useState("");
//   const [selectedRegType, setSelectedRegType] = useState("");
//   const [selectedService, setSelectedService] = useState("");

//   const handleChange = (value) => {
//     setValue(value);
//   };

//   // Executive Dropdowns

//   const executive = ["Name 1", "Name 2", "Name 3", "Name 4"];

//   const filteredExecutive = executive.filter((executive) =>
//     executive.toLowerCase().includes(searchInput.toLowerCase())
//   );

//   const handleExecutiveSelect = (executive) => {
//     setSelectedExecutive(executive);
//     setSearchInput("");
//   };

//   // Project Status Dropdowns

//   const stage = [
//     "Advance Payment received",
//     "KYC Done",
//     "Project Started",
//     "Certified",
//     "1st Surveillance",
//     "2nd Surveillance",
//     "ReCertification",
//   ];

//   const filteredStage = stage.filter((stage) =>
//     stage.toLowerCase().includes(searchInput.toLowerCase())
//   );

//   const handleStageSelect = (stage) => {
//     setSelectedStage(stage);
//     setSearchInput("");
//   };

//   // Stages registrationTypes

//   const registrationTypes = [
//     "Sole Proprietorship/Individual Entrepreneurship",
//     "Partnership",
//     "Limited Liability Company (LLC)",
//     "Corporation",
//     "Nonprofit Organization",
//     "Cooperative",
//     "Joint Venture",
//     "Branch Office",
//     "Subsidiary Company",
//     "State-Owned Enterprise",
//     "One-Person Company",
//     "Public Company",
//     "Family-Owned Business",
//     "Offshore Company",
//     "Social Enterprise",
//     "LLP",
//   ];

//   const filteredRegTypes = registrationTypes.filter((registrationType) =>
//     registrationType.toLowerCase().includes(searchInput.toLowerCase())
//   );

//   const handleRegType = (registrationType) => {
//     setSelectedRegType(registrationType);
//     setSearchInput("");
//   };

//   // Services Dropdowns

//   const services = [
//     "Audit & Certification",
//     "Consultation audit & Certification",
//     "Consultation",
//     "Training",
//   ];

//   const filteredServices = services.filter((service) =>
//     service.toLowerCase().includes(searchInput.toLowerCase())
//   );

//   const handleServiceSelect = (service) => {
//     setSelectedService(service);
//     setSearchInput("");
//   };

//   const customStyles = {
//     option: (provided, state) => ({
//       ...provided,
//       color: state.isFocused ? "#D80000" : "black",
//       backgroundColor: state.isFocused ? "#D8000026" : "white",
//     }),
//     // control: (provided) => ({
//     //   ...provided,
//     //   // borderColor: "#D80000",
//     // }),
//   };

//   return (
//     <Box>
//       <Navbar
//         isSidebarExpanded={isSidebarExpanded}
//         currentPage="Add New Customer"
//       />
//       <Box
//         position="absolute"
//         top={1}
//         left={leftMargin}
//         transition="left 0.2s ease, width 0.2s ease"
//         width={width}
//         height={"auto"}
//       >
//         <Box
//           boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
//           p={3}
//           borderRadius={7}
//           position={"relative"}
//           top={100}
//         >
//           <Grid templateColumns="repeat(4, 1fr)" gap={4}>
//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Date :
//               </FormLabel>
//               <Input type="date" name="Date" required />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Name
//               </FormLabel>
//               <Input type="text" name="Name" required />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Executive :
//               </FormLabel>
//               <Menu>
//                 <MenuButton
//                   as={Button}
//                   rightIcon={<IoChevronDownOutline />}
//                   width="100%"
//                   textAlign="left"
//                   color={"#6f6b7d"}
//                   textDecoration={"none"}
//                 >
//                   {selectedExecutive ? selectedExecutive : "Select Executive"}
//                 </MenuButton>
//                 <MenuList maxHeight="200px" overflowY="auto">
//                   <Box p={2}>
//                     <Input
//                       placeholder="Executive..."
//                       value={searchInput}
//                       onChange={(e) => setSearchInput(e.target.value)}
//                     />
//                   </Box>
//                   {filteredExecutive.length > 0 ? (
//                     filteredExecutive.map((executive, index) => (
//                       <MenuItem
//                         _hover={{
//                           bg: "#D8000026",
//                           textColor: "#D80000",
//                         }}
//                         key={index}
//                         onClick={() => handleExecutiveSelect(executive)}
//                       >
//                         {executive}
//                       </MenuItem>
//                     ))
//                   ) : (
//                     <MenuItem disabled>No matches found</MenuItem>
//                   )}
//                 </MenuList>
//               </Menu>
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Client Name :
//               </FormLabel>
//               <Input type="text" name="Client Name" required />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Email Address
//               </FormLabel>
//               <Input type="email" name="Email" required />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Phone Number
//               </FormLabel>
//               <Input type="telephone" name="Phone" required />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 State
//               </FormLabel>
//               <Input type="text" name="State" placeholder="Enter your State" />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Country:
//               </FormLabel>
//               <Select
//                 options={options}
//                 styles={customStyles}
//                 maxMenuHeight={"200px"}
//                 value={value}
//                 onChange={handleChange}
//                 placeholder="Select your country"
//               />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Stage :
//               </FormLabel>
//               <Menu>
//                 <MenuButton
//                   as={Button}
//                   rightIcon={<IoChevronDownOutline />}
//                   width="100%"
//                   textAlign="left"
//                   color={"#6f6b7d"}
//                   background={"none"}
//                   border={"1px solid #dbdade"}
//                   _hover={{bg: "none"}}
//                 >
//                   {selectedStage ? selectedStage : "Select Stage"}
//                 </MenuButton>
//                 <MenuList maxHeight="200px" overflowY="auto">
//                   <Box p={2}>
//                     <Input
//                       placeholder="Stage..."
//                       value={searchInput}
//                       onChange={(e) => setSearchInput(e.target.value)}
//                     />
//                   </Box>
//                   {filteredStage.length > 0 ? (
//                     filteredStage.map((stage, index) => (
//                       <MenuItem
//                         _hover={{
//                           bg: "#D8000026",
//                           textColor: "#D80000",
//                         }}
//                         key={index}
//                         onClick={() => handleStageSelect(stage)}
//                       >
//                         {stage}
//                       </MenuItem>
//                     ))
//                   ) : (
//                     <MenuItem disabled>No matches found</MenuItem>
//                   )}
//                 </MenuList>
//               </Menu>
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Due Date
//               </FormLabel>
//               <Input type="date" name="Date" />
//             </FormControl>
//           </Grid>

//           <Grid templateColumns="repeat(4, 1fr)" gap={4} mb={10}>
//             <Button
//               background={"#a8aaae"}
//               color={"white"}
//               height={8}
//               mt={9}
//               w={200}
//             >
//               Choose Frequency
//             </Button>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Company Address :
//               </FormLabel>
//               <Input type="text" name="Company Address" />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 No. Of Employees:
//               </FormLabel>
//               <Input
//                 type="number"
//                 name="Employees"
//                 placeholder="Enter your no. of. Emp"
//               />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Website :
//               </FormLabel>
//               <Input type="text" name="Website" />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Nature of Business:
//               </FormLabel>
//               <Input type="text" name="Nature of Business" required />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Company Registration Type:
//               </FormLabel>
//               <Menu>
//                 <MenuButton
//                   as={Button}
//                   rightIcon={<IoChevronDownOutline />}
//                   width="100%"
//                   textAlign="left"
//                   color={"#6f6b7d"}
//                   background={"none"}
//                   border={"1px solid #dbdade"}
//                   _hover={{bg: "none"}}
//                 >
//                   {selectedRegType ? selectedRegType : "Select Type"}
//                 </MenuButton>
//                 <MenuList maxHeight="200px" width={"280px"} overflowY="auto">
//                   <Box p={2}>
//                     <Input
//                       placeholder="Reg Type..."
//                       value={searchInput}
//                       onChange={(e) => setSearchInput(e.target.value)}
//                     />
//                   </Box>
//                   {filteredRegTypes.length > 0 ? (
//                     filteredRegTypes.map((registrationType, index) => (
//                       <MenuItem
//                         _hover={{
//                           bg: "#D8000026",
//                           textColor: "#D80000",
//                         }}
//                         key={index}
//                         onClick={() => handleRegType(registrationType)}
//                       >
//                         {registrationType}
//                       </MenuItem>
//                     ))
//                   ) : (
//                     <MenuItem disabled>No matches found</MenuItem>
//                   )}
//                 </MenuList>
//               </Menu>
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Standard :
//               </FormLabel>
//               <Input type="text" name="Standard" />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 color={"#6f6b7d"}
//                 mb={-0.1}
//               >
//                 Service Type:
//               </FormLabel>
//               <Menu>
//                 <MenuButton
//                   as={Button}
//                   rightIcon={<IoChevronDownOutline />}
//                   width="100%"
//                   textAlign="left"
//                   color={"#6f6b7d"}
//                 >
//                   {selectedService ? selectedService : "Select Service"}
//                 </MenuButton>
//                 <MenuList maxHeight="200px" overflowY="auto">
//                   <Box p={2}>
//                     <Input
//                       placeholder="Service..."
//                       value={searchInput}
//                       onChange={(e) => setSearchInput(e.target.value)}
//                     />
//                   </Box>
//                   {filteredServices.length > 0 ? (
//                     filteredServices.map((service, index) => (
//                       <MenuItem
//                         _hover={{
//                           bg: "#D8000026",
//                           textColor: "#D80000",
//                         }}
//                         key={index}
//                         onClick={() => handleServiceSelect(service)}
//                       >
//                         {service}
//                       </MenuItem>
//                     ))
//                   ) : (
//                     <MenuItem disabled>No matches found</MenuItem>
//                   )}
//                 </MenuList>
//               </Menu>
//             </FormControl>
//           </Grid>

//           <Button
//             background={"#D80000"}
//             color={"white"}
//             height={8}
//             position="absolute"
//             bottom={4}
//             right={8}
//           >
//             Save Customer
//           </Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default AddNewCustomers;
