import {
    Box,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Grid,
    Textarea,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
  } from "@chakra-ui/react";
  import React, { useState } from "react";
  import Navbar from "./Sidebar/Navbar";
import { IoChevronDownOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
  
  const AddExpense = ({ isSidebarExpanded }) => {
    
    const [value, setValue] = React.useState(null);

    const width = isSidebarExpanded
      ? "calc(98vw - 270px)"
      : "calc(98vw - 110px)";
    const leftMargin = isSidebarExpanded ? "270px" : "110px";

    const [searchInput, setSearchInput] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedPayment, setSelectedPayment] = useState("");
    const [selectedBank, setSelectedBank] = useState("");
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedBranch, setSelectedBranch] = useState("");

    const navigate = useNavigate();


    const handleChange = (value) => {
        setValue(value);
      };

    const handleButton = (e) => {
        e.preventDefault();
        navigate("/expense");
    }


    // User Dropdowns
  
    const user = ["Name 1", "Name 2", "Name 3", "Name 4"];
  
    const filteredUser = user.filter((user) =>
        user.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    const handleUserSelect = (user) => {
      setSelectedUser(user);
      setSearchInput("");
    };


    // Category dropdowns

    const category = [
        "Marketing",
        "Others",
        "Rent",
        "Salary",
      ];
    
      const filteredCategory = category.filter((category) =>
        category.toLowerCase().includes(searchInput.toLowerCase())
      );
    
      const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setSearchInput("");
      };

    const payment = [
        "Cash",
        "Cheque",
        "Google Pay",
        "Online Transfer",
        "Others",
        "Payment Gateway",
        "Paytm",
      ];
    
      const filteredPayment = payment.filter((payment) =>
        payment.toLowerCase().includes(searchInput.toLowerCase())
      );
    
      const handlePaymentSelect = (payment) => {
        setSelectedPayment(payment);
        setSearchInput("");
      };

// Bank Dropdowns

  const bank = [
    
  ];

  const filteredBank= bank.filter((bank) =>
    bank.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleBankSelect = (bank) => {
    setSelectedBank(bank);
    setSearchInput("");
  };

// Client Dropdowns

  const client = [
    "Client-1",
    "Client-2",
    "Client-3",
    "Client-4",
  ];

  const filteredClient= client.filter((client) =>
    client.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleClientSelect = (client) => {
    setSelectedClient(client);
    setSearchInput("");
  };

  // Branch Dropdowns

  const branch = [
    
  ];

  const filteredBranch= bank.filter((branch) =>
    branch.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleBranchSelect = (branch) => {
    setSelectedBranch(branch);
    setSearchInput("");
  };

  
    return (
      <Box>
        <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Add Expense" />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          p={3}
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Heading>
          <Button position={"absolute"} top={4} right={4} mb={10} border={"1px solid #D80000"} color={"#D80000"} height={"30px"} fontFamily={"Public Sans"} _hover={{bg: "#D8000026",}} onClick={handleButton}>Go Back</Button>
          </Heading>
          
          <Grid templateColumns="repeat(4, 1fr)" gap={4} mt={"70px"}>
            <FormControl mb={2}>
              <FormLabel mb={-0.2} color={"#151617"}>ID :</FormLabel>
              <Input type="text" name="ID" required/>
            </FormControl>
  
            <FormControl mb={2}>
              <FormLabel mb={-0.2} color={"#151617"}>Date</FormLabel>
              <Input type="date" name="Date" required />
            </FormControl>
  
            <FormControl mb={2}>
                <FormLabel
                  fontSize={"0.9375rem"}
                  lineHeight={"1.47"}
                  mb={-0.2}
                  color={"#151617"}
                >
                  Category :
                </FormLabel>
                <Menu>
                  <MenuButton
                    as={Button}
                    width="100%"
                    textAlign="left"
                    color={"#6f6b7d"}
                    background={"none"}
                    border={"1px solid #dbdade"}
                    _hover={{bg: "none"}}
                  >
                    {selectedCategory ? selectedCategory : "Select Category"}
                  </MenuButton>
                  <MenuList maxHeight="200px" overflowY="auto">
                    <Box p={2}>
                      <Input
                        placeholder="Category..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </Box>
                    {filteredCategory.length > 0 ? (
                      filteredCategory.map((category, index) => (
                        <MenuItem
                          _hover={{
                            bg: "#D8000026",
                            textColor: "#D80000",
                          }}
                          key={index}
                          onClick={() => handleCategorySelect(category)}
                        >
                          {category}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No matches found</MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </FormControl>
  
  
            <FormControl mb={2}>
              <FormLabel mb={-0.2} color={"#151617"}>Amount :</FormLabel>
              <Input
                type="text"
                name="Amount"
              />
            </FormControl>
  
            <FormControl mb={2}>
                <FormLabel
                  fontSize={"0.9375rem"}
                  lineHeight={"1.47"}
                  mb={-0.2}
                  color={"#151617"}
                >
                  User :
                </FormLabel>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDownOutline />}
                    width="100%"
                    textAlign="left"
                    color={"#6f6b7d"}
                    background={"none"}
                    border={"1px solid #dbdade"}
                    _hover={{bg: "none"}}
                  >
                    {selectedUser ? selectedUser : "Select User"}
                  </MenuButton>
                  <MenuList maxHeight="200px" overflowY="auto">
                    <Box p={2}>
                      <Input
                        placeholder="User..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </Box>
                    {filteredUser.length > 0 ? (
                      filteredUser.map((user, index) => (
                        <MenuItem
                          _hover={{
                            bg: "#D8000026",
                            textColor: "#D80000",
                          }}
                          key={index}
                          onClick={() => handleUserSelect(user)}
                        >
                          {user}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No matches found</MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </FormControl>
  
            <FormControl mb={2}>
              <FormLabel mb={-0.2} color={"#151617"}>Details :</FormLabel>
              <Input
                type="text"
                name="Details"
              />
            </FormControl>
  
            <FormControl mb={2}>
              <FormLabel mb={-0.2} color={"#151617"}>Tax :</FormLabel>
              <Input type="number"   name="Tax" />
            </FormControl>
  
  
            <FormControl mb={2}>
                <FormLabel
                  fontSize={"0.9375rem"}
                  lineHeight={"1.47"}
                  mb={-0.2}
                  color={"#151617"}
                >
                  Payment Mode :
                </FormLabel>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDownOutline />}
                    width="100%"
                    textAlign="left"
                    color={"#6f6b7d"}
                    background={"none"}
                    border={"1px solid #dbdade"}
                    _hover={{bg: "none"}}
                  >
                    {selectedPayment ? selectedPayment : "Select Payment"}
                  </MenuButton>
                  <MenuList maxHeight="200px" overflowY="auto">
                    <Box p={2}>
                      <Input
                        placeholder="Payment..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </Box>
                    {filteredPayment.length > 0 ? (
                      filteredPayment.map((payment, index) => (
                        <MenuItem
                          _hover={{
                            bg: "#D8000026",
                            textColor: "#D80000",
                          }}
                          key={index}
                          onClick={() => handlePaymentSelect(payment)}
                        >
                          {payment}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No matches found</MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </FormControl>
  
              <FormControl mb={2}>
                <FormLabel
                  fontSize={"0.9375rem"}
                  lineHeight={"1.47"}
                  mb={-0.2}
                  color={"#151617"}
                >
                  Bank Account :
                </FormLabel>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDownOutline />}
                    width="100%"
                    textAlign="left"
                    color={"#6f6b7d"}
                    background={"none"}
                    border={"1px solid #dbdade"}
                    _hover={{bg: "none"}}
                  >
                    {selectedBank ? selectedBank : "Select Bank"}
                  </MenuButton>
                  <MenuList maxHeight="200px" overflowY="auto">
                    <Box p={2}>
                      <Input
                        placeholder="Bank..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </Box>
                    {filteredBank.length > 0 ? (
                      filteredBank.map((bank, index) => (
                        <MenuItem
                          _hover={{
                            bg: "#D8000026",
                            textColor: "#D80000",
                          }}
                          key={index}
                          onClick={() => handleBankSelect(bank)}
                        >
                          {bank}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No matches found</MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </FormControl>
  
  
            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#151617"}
              >
                Client :
              </FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoChevronDownOutline />}
                  width="100%"
                  textAlign="left"
                  color={"#6f6b7d"}
                  background={"none"}
                  border={"1px solid #dbdade"}
                  _hover={{bg: "none"}}
                >
                  {selectedClient ? selectedClient : "Select Client"}
                </MenuButton>
                <MenuList maxHeight="200px" width={"250px"} overflowY="auto">
                  <Box p={2} >
                    <Input
                      placeholder="Client..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Box>
                  {filteredClient.length > 0 ? (
                    filteredClient.map((client, index) => (
                      <MenuItem
                        _hover={{
                          bg: "#D8000026",
                          textColor: "#D80000",
                        }}
                        key={index}
                        onClick={() => handleClientSelect(client)}
                      >
                        {client}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No matches found</MenuItem>
                  )}
                </MenuList>
              </Menu>
            </FormControl>
  
            <FormControl mb={2}>
                <FormLabel
                  fontSize={"0.9375rem"}
                  lineHeight={"1.47"}
                  mb={-0.2}
                  color={"#151617"}
                >
                  Branch :
                </FormLabel>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDownOutline />}
                    width="100%"
                    textAlign="left"
                    color={"#6f6b7d"}
                    background={"none"}
                    border={"1px solid #dbdade"}
                    _hover={{bg: "none"}}
                  >
                    {selectedBranch ? selectedBranch : "Select Branch"}
                  </MenuButton>
                  <MenuList maxHeight="200px" overflowY="auto">
                    <Box p={2}>
                      <Input
                        placeholder="Branch..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </Box>
                    {filteredBranch.length > 0 ? (
                      filteredBranch.map((branch, index) => (
                        <MenuItem
                          _hover={{
                            bg: "#D8000026",
                            textColor: "#D80000",
                          }}
                          key={index}
                          onClick={() => handleBranchSelect(branch)}
                        >
                          {branch}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No matches found</MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </FormControl>

              <FormControl mb={2}>
              <FormLabel mb={-0.2} color={"#151617"}>Project :</FormLabel>
              <Input
                type="text"
                name="Website"
                placeholder="Select Project"
              />
            </FormControl>
  
            <FormControl mb={2}>
              <FormLabel mb={-0.2} color={"#151617"}>Receipt:</FormLabel>
              <Input
                type="file"
                name="Receipt"
                accept=".jpg, .png, .jpeg."
                pt={1}
                required
              />
            </FormControl>
  

          </Grid>

          <FormControl mb={2}>
              <FormLabel mb={-0.2} color={"#151617"}>Comments :</FormLabel>
              <Textarea
                type="text"
                name="Comments"
                mb={10}
              />
            </FormControl>
  
          <Button
            background={"#D80000"}
            color={"white"}
            height={8}
            position="absolute"
            bottom={4}
            right={8}
          >
            Add Expense
          </Button>
        </Box>
      </Box>
      </Box>
    );
  };
  
  export default AddExpense;
  