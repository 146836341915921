// import {
//   Box,
//   FormControl,
//   FormLabel,
//   Heading,
//   Input,
//   Grid,
//   Textarea,
//   Button,
//   Table,
//   Thead,
//   TableContainer,
//   Tr,
//   Th,
//   Tbody,
//   Td,
//   MenuItem,
//   MenuList,
//   MenuButton,
//   Menu,
// } from "@chakra-ui/react";
// import React, { useState } from "react";
// import Navbar from "./Sidebar/Navbar";
// import { IoChevronDownOutline } from "react-icons/io5";


// const AddNewProjects = ({ isSidebarExpanded }) => {

//   const [value, setValue] = React.useState(null);
//   const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
//   const leftMargin = isSidebarExpanded ? "270px" : "110px";


//   const [searchInput, setSearchInput] = useState("");
//   const [selectedExecutive, setSelectedExecutive] = useState("");
//   const [selectedClient, setSelectedClient] = useState("");
//   const [selectedProjectStatus, setSelectedProjectStatus] = useState("");

//   const handleChange = (value) => {
//     setValue(value);
//   };


//   // Client Dropdowns

//   const client = [
    
//   ];

//   const filteredClient= client.filter((client) =>
//     client.toLowerCase().includes(searchInput.toLowerCase())
//   );

//   const handleClientSelect = (client) => {
//     setSelectedClient(client);
//     setSearchInput("");
//   };


//   // Executive Dropdowns

//   const executive = [
//     "Name 1",
//     "Name 2",
//     "Name 3",
//     "Name 4",
//   ];

//   const filteredExecutive= executive.filter((executive) =>
//     executive.toLowerCase().includes(searchInput.toLowerCase())
//   );

//   const handleExecutiveSelect = (executive) => {
//     setSelectedExecutive(executive);
//     setSearchInput("");
//   };

// // Project Status Dropdowns

// const ProjectStatus = [
//   "GAP assessment",
//   "GAP report Submitted",
//   "Documentation",
//   "Documentation training",
//   "Awareness training",
//   "IA Training",
//   "Documentation review",
//   "Internal audit",
//   "MRM",
//   "Mock audit",
//   "External audit",
//   "Certification shared",
//   "Balance payment collection",
// ];

// const filteredProjectStatus = ProjectStatus.filter((projectstatus) =>
//   projectstatus.toLowerCase().includes(searchInput.toLowerCase())
// );

// const handleProjectStatusSelect = (projectstatus) => {
//   setSelectedProjectStatus(projectstatus);
//   setSearchInput("");
// };


//   return (
//     <Box>
//       <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Add Project" />
//       <Box
//         position="absolute"
//         top={1}
//         left={leftMargin}
//         transition="left 0.2s ease, width 0.2s ease"
//         width={width}
//         height={"auto"}
//       >
//         <Box
//           boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
//           p={3}
//           borderRadius={7}
//           position={"relative"}
//           top={100}
//         >
//           <Grid templateColumns="repeat(4, 1fr)" gap={4}>
//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Project Name :
//               </FormLabel>
//               <Input type="text" name="Name" required />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Priority :
//               </FormLabel>
//               <Input type="text" name="Priority" required />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Client :
//               </FormLabel>
//               <Menu>
//                 <MenuButton
//                   as={Button}
//                   rightIcon={<IoChevronDownOutline />}
//                   width="100%"
//                   textAlign="left"
//                   color={"#6f6b7d"}
//                   background={"none"}
//                   border={"1px solid #dbdade"}
//                   _hover={{bg: "none"}}
//                 >
//                   {selectedClient ? selectedClient : "Select Client"}
//                 </MenuButton>
//                 <MenuList maxHeight="200px" overflowY="auto">
//                   <Box p={2}>
//                     <Input
//                       placeholder="Client..."
//                       value={searchInput}
//                       onChange={(e) => setSearchInput(e.target.value)}
//                     />
//                   </Box>
//                   {filteredClient.length > 0 ? (
//                     filteredClient.map((client, index) => (
//                       <MenuItem
//                         _hover={{
//                           bg: "#D8000026",
//                           textColor: "#D80000",
//                         }}
//                         key={index}
//                         onClick={() => handleClientSelect(client)}
//                       >
//                         {client}
//                       </MenuItem>
//                     ))
//                   ) : (
//                     <MenuItem disabled>No matches found</MenuItem>
//                   )}
//                 </MenuList>
//               </Menu>
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Sales Executive / Consultant:
//               </FormLabel>
//               <Menu>
//                 <MenuButton
//                   as={Button}
//                   rightIcon={<IoChevronDownOutline />}
//                   width="100%"
//                   textAlign="left"
//                   color={"#6f6b7d"}
//                   background={"none"}
//                   border={"1px solid #dbdade"}
//                   _hover={{bg: "none"}}
//                 >
//                   {selectedExecutive ? selectedExecutive : "Select Executive"}
//                 </MenuButton>
//                 <MenuList maxHeight="200px" overflowY="auto">
//                   <Box p={2}>
//                     <Input
//                       placeholder="Executive..."
//                       value={searchInput}
//                       onChange={(e) => setSearchInput(e.target.value)}
//                     />
//                   </Box>
//                   {filteredExecutive.length > 0 ? (
//                     filteredExecutive.map((executive, index) => (
//                       <MenuItem
//                         _hover={{
//                           bg: "#D8000026",
//                           textColor: "#D80000",
//                         }}
//                         key={index}
//                         onClick={() => handleExecutiveSelect(executive)}
//                       >
//                         {executive}
//                       </MenuItem>
//                     ))
//                   ) : (
//                     <MenuItem disabled>No matches found</MenuItem>
//                   )}
//                 </MenuList>
//               </Menu>
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Planned Project Kick off date :*
//               </FormLabel>
//               <Input type="date" name="Planned Project Kick off" />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Actual Project Kick off date :*
//               </FormLabel>
//               <Input type="date" name="Actual Project Kick off" />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Planned Project Closure date :*
//               </FormLabel>
//               <Input type="date" name="Planned Project Closure" />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Actual Project Closure date :*
//               </FormLabel>
//               <Input type="date" name="Actual Project Closure" required />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Project Team size :*
//               </FormLabel>
//               <Input type="text" name="Project Team size" required />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Team members :*
//               </FormLabel>
//               <Input type="text" name="Team members" />
//             </FormControl>

//             <FormControl mb={2}>
//               <FormLabel
//                 fontSize={"0.9375rem"}
//                 lineHeight={"1.47"}
//                 mb={-0.2}
//                 color={"#6f6b7d"}
//               >
//                 Project Status:
//               </FormLabel>
//               <Menu>
//                 <MenuButton
//                   as={Button}
//                   rightIcon={<IoChevronDownOutline />}
//                   width="100%"
//                   textAlign="left"
//                   color={"#6f6b7d"}
//                   background={"none"}
//                   border={"1px solid #dbdade"}
//                   _hover={{bg: "none"}}
//                 >
//                   {selectedProjectStatus ? selectedProjectStatus : "Select Project Status"}
//                 </MenuButton>
//                 <MenuList maxHeight="200px" overflowY="auto">
//                   <Box p={2}>
//                     <Input
//                       placeholder="Project Status..."
//                       value={searchInput}
//                       onChange={(e) => setSearchInput(e.target.value)}
//                     />
//                   </Box>
//                   {filteredProjectStatus.length > 0 ? (
//                     filteredProjectStatus.map((projectstatus, index) => (
//                       <MenuItem
//                         _hover={{
//                           bg: "#D8000026",
//                           textColor: "#D80000",
//                         }}
//                         key={index}
//                         onClick={() => handleProjectStatusSelect(projectstatus)}
//                       >
//                         {projectstatus}
//                       </MenuItem>
//                     ))
//                   ) : (
//                     <MenuItem disabled>No matches found</MenuItem>
//                   )}
//                 </MenuList>
//               </Menu>
//             </FormControl>
//           </Grid>

//           <FormControl mb={2}>
//             <FormLabel
//               fontSize={"0.9375rem"}
//               lineHeight={"1.47"}
//               color={"#6f6b7d"}
//               mt={"1rem"}
//             >
//               Team Details :
//             </FormLabel>
//           </FormControl>


//           <Table variant="simple" size="md" border="1px solid #6f6b7d" mb={1}>
//             <Thead>
//               <Tr>
//                 <Th border="1px solid #dbdade"></Th>
//                 <Th border="1px solid #dbdade">Name</Th>
//                 <Th border="1px solid #dbdade">Contact Number</Th>
//                 <Th border="1px solid #dbdade">Email Address</Th>
//               </Tr>
//             </Thead>
//             <Tbody>
//               {[
//                 "HR team details",
//                 "IT administration team details",
//                 "Administration team details",
//                 "Procurement team details",
//                 "Product Engineering team details",
//                 "Quality team details",
//                 "Operations team details",
//                 "Sales team details",
//                 "Compliance team details",
//               ].map((team, index) => (
//                 <Tr key={index}>
//                   <Td border="1px solid #dbdade">{team}</Td>
//                   <Td border="1px solid #dbdade">
//                     <Input />
//                   </Td>
//                   <Td border="1px solid #dbdade">
//                     <Input />
//                   </Td>
//                   <Td border="1px solid #dbdade">
//                     <Input />
//                   </Td>
//                 </Tr>
//               ))}
//             </Tbody>
//           </Table>

//           <Grid templateColumns="repeat(4, 1fr)" gap={4}>
//           <FormControl mb={2}>
//             <FormLabel
//               fontSize={"0.9375rem"}
//               lineHeight={"1.47"}
//               mb={-0.2}
//               color={"#6f6b7d"}
//             >
//               Certificate Issue date :
//             </FormLabel>
//             <Input type="date" name="Certificate Issue date" required />
//           </FormControl>

//           <FormControl mb={2}>
//             <FormLabel
//               fontSize={"0.9375rem"}
//               lineHeight={"1.47"}
//               mb={-0.2}
//               color={"#6f6b7d"}
//             >
//               1st Surveillance due date :
//             </FormLabel>
//             <Input type="date" name="1st Surveillance due date" required />
//           </FormControl>

//           <FormControl mb={2}>
//             <FormLabel
//               fontSize={"0.9375rem"}
//               lineHeight={"1.47"}
//               mb={-0.2}
//               color={"#6f6b7d"}
//             >
//               2nd surveillance due date :
//             </FormLabel>
//             <Input type="date" name="2nd surveillance due date" required />
//           </FormControl>

//           <FormControl mb={2}>
//             <FormLabel
//               fontSize={"0.9375rem"}
//               lineHeight={"1.47"}
//               mb={-0.2}
//               color={"#6f6b7d"}
//             >
//               ReCertification due date :
//             </FormLabel>
//             <Input type="date" name="ReCertification due date" required />
//           </FormControl>

//           <FormControl mb={2}>
//             <FormLabel
//               fontSize={"0.9375rem"}
//               lineHeight={"1.47"}
//               mb={-0.2}
//               color={"#6f6b7d"}
//             >
//               Closure Value :
//             </FormLabel>
//             <Input type="text" name="Closure Value" required />
//           </FormControl>

//           <FormControl mb={2}>
//             <FormLabel
//               fontSize={"0.9375rem"}
//               lineHeight={"1.47"}
//               mb={-0.2}
//               color={"#6f6b7d"}
//             >
//               Gross Margin :
//             </FormLabel>
//             <Input type="text" name="Gross Margin " required />
//           </FormControl>

//           </Grid>

//           <FormControl mb={2}>
//             <FormLabel
//               fontSize={"0.9375rem"}
//               lineHeight={"1.47"}
//               mb={-0.2}
//               color={"#6f6b7d"}
//             >
//               Description :
//             </FormLabel>
//             <Textarea  name="des" mb={10} required />
//           </FormControl>

//           <Button
//             background={"#D80000"}
//             color={"white"}
//             height={8}
//             position="absolute"
//             bottom={4}
//             right={4}
//             mt={8}
//           >
//             Add Project
//           </Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default AddNewProjects;
