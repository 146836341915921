import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  Grid,
  Heading,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import Navbar from "../../Sidebar/Navbar";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

const OverallPurchaseReport = ({ isSidebarExpanded }) => {
  const [data, setData] = useState([]);
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const navigate = useNavigate();

  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/reports");
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Purchase Order Report"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Flex p={3} mb={4}>
            <Heading
              color={"#151617"}
              as="h5"
              fontSize={"18px"}
              fontWeight={"bold"}
              fontFamily={"Public Sans, sans-serif"}
              mt={2}
            >
              {data.length} Record(s) Found
            </Heading>
            <Box position={"absolute"} right={10}>
              <Flex>
                <Flex
                  fontSize={"14px"}
                  border={"1px solid red"}
                  lineHeight={7}
                  borderRadius={8}
                  fontFamily={"Public Sans, sans-serif"}
                  color={"#D80000"}
                >
                  <Text
                    borderRight={"1px solid #D80000"}
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}
                    onClick={onOpen}
                  >
                    Filter
                  </Text>
                  <Text
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}
                    onClick={handleOnClick}
                  >
                    Go Back
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <TableContainer>
            <Table mt={4}>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th color={"white"}>#</Th>
                  <Th color={"white"}>PO Number </Th>
                  <Th color={"white"}>PO Date </Th>
                  <Th color={"white"}>Creator</Th>
                  <Th color={"white"}>Vendor</Th>
                  <Th color={"white"}>Executive </Th>
                  <Th color={"white"}>Your Billing Address </Th>
                  <Th color={"white"}>Your Shipping Address </Th>
                  <Th color={"white"}>Currency </Th>
                  <Th color={"white"}>Status</Th>
                  <Th color={"white"}>Item</Th>
                  <Th color={"white"}>Description</Th>
                  <Th color={"white"}>Qty</Th>
                  <Th color={"white"}>Unit</Th>
                  <Th color={"white"}>Rate</Th>
                  <Th color={"white"}>Tax</Th>
                  <Th color={"white"}>Amount</Th>
                  <Th color={"white"}>Discount</Th>
                  <Th color={"white"}>Shipping</Th>
                  <Th color={"white"}>Shipping GST (%)</Th>
                  <Th color={"white"}>Total</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.length === 0 ? (
                  <Tr>
                    <Td colSpan="16">
                      <Center py={10}>
                        <Text fontSize={"26px"} color={"#5D596C"} fontWeight={500}>
                          No Records Found.
                        </Text>
                      </Center>
                    </Td>
                  </Tr>
                ) : (
                  data.map((purchase, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{purchase.po_number}</Td>
                      <Td>{purchase.po_date}</Td>
                      <Td>{purchase.creator}</Td>
                      <Td>{purchase.vendor}</Td>
                      <Td>{purchase.executive}</Td>
                      <Td>{purchase.your_billing_address}</Td>
                      <Td>{purchase.your_shipping_address}</Td>
                      <Td>{purchase.currency}</Td>
                      <Td>{purchase.status}</Td>
                      <Td>{purchase.item}</Td>
                      <Td>{purchase.description}</Td>
                      <Td>{purchase.qty}</Td>
                      <Td>{purchase.unit}</Td>
                      <Td>{purchase.rate}</Td>
                      <Td>{purchase.tax}</Td>
                      <Td>{purchase.amount}</Td>
                      <Td>{purchase.discount}</Td>
                      <Td>{purchase.shipping}</Td>
                      <Td>{purchase.shipping_gst}</Td>
                      <Td>{purchase.total}</Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size={"md"}
        >
          <DrawerOverlay />
          <DrawerContent w="100%" maxW="550px" overflowX="hidden">
            <DrawerCloseButton />
            <DrawerHeader>Filters</DrawerHeader>

            <DrawerBody>
              <Grid templateColumns="repeat(2, 1fr)" gap={4} w="100%">
              <FormControl mb={2} isRequired>
                  <Select name="range" required>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                  </Select>
                </FormControl>
                <FormControl mb={2}>
                  <DatePicker
                    selected={selectedFromDate}
                    onChange={(date) => setSelectedFromDate(date)}
                    placeholderText="From Date"
                    customInput={<Input width="240px" />}
                    dateFormat="MM/dd/yyyy"
                  />
                </FormControl>
                <FormControl mb={2}>
                  <DatePicker
                    selected={selectedToDate}
                    onChange={(date) => setSelectedToDate(date)}
                    placeholderText="To Date"
                    customInput={<Input width="240px" />}
                    dateFormat="MM/dd/yyyy"
                  />
                </FormControl>
              </Grid>
            </DrawerBody>

            <DrawerFooter>
            <Button width={"100%"} height={7} mr={"7"} background={"#D80000"} color={"white"}>
                Filter
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Box>
    </Box>
  );
};

export default OverallPurchaseReport;
