import {
    Box,
    Button,
    Center,
    Checkbox, 
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Heading,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Select,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
  } from "@chakra-ui/react";
  import Navbar from "../../Sidebar/Navbar";
  import { useNavigate } from "react-router-dom";
  import { FiEdit2, FiFileText, FiMoreVertical, FiPrinter } from "react-icons/fi";
  import React, { useState } from "react";
  import { ChevronDownIcon, DeleteIcon, InfoIcon } from "@chakra-ui/icons";
import { IoChevronDownOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
  
  const UpdateReport2 = ({ isSidebarExpanded }) => {
    const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
    const leftMargin = isSidebarExpanded ? "270px" : "110px";
    const navigate = useNavigate();
  
    const handleOnClick = (e) => {
      e.preventDefault();
      navigate("/reports");
    };
  
    const handleEditUser = (userId) => {
      navigate(`/edituser/${userId}`);
    };
  
    const [userData, setUserData] = useState([
        {
            updateOn: "november/06/2023",
            dateOfAddition: "LD470",
            id: "Clayton wilson",
            name: "clayton@wilsonsigns.com.au",
            companyName: "0412267062",
            contactNo: "0412267062",
            email: "0412267062",
            executive: "Wilsonsigns",
            updateMessage: "Active",
            followUpDate: "xyz",
            followUpTime: "Australia",
            stage:"xyz"
            
           
           
            
        },
    ]);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const totalPages = Math.ceil(userData.length / rowsPerPage);
    const currentUsers = userData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );

    const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [selectedExecutive, setSelectedExecutive] = useState("")
  const [searchInput, setSearchInput] = useState("");

  
    const startEntry = (currentPage - 1) * rowsPerPage + 1;
    const endEntry = Math.min(currentPage * rowsPerPage, userData.length);
  
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
  
    const goToPage = (pageNumber) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      }
    };

    const status = [
      "Unattended",
      "Active",
      "Spoke",
      "Proposal Sent",
      "Meeting Fixed",
      "Met",
      "Converted to Deal",
      "Converted to Fresh",
      "Lost",
      "Closed",
    ];
  
    const filteredStatus = status.filter((status) =>
      status.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    const handleStatusSelect = (status) => {
      setSelectedStatus(status);
      setSearchInput("");
    };
  
    const sources = ["Upsale", "Cross sale", "Lead Generation", "Referral"];
  
    const filteredSource = sources.filter((source) =>
      source.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    const handleSourceSelect = (source) => {
      setSelectedSource(source);
      setSearchInput("");
    };
  
    const executive = ["Name 1", "Name 2", "Name 3", "Name 4"];
  
    const filteredExecutive = executive.filter((executive) =>
      executive.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    const handleExecutiveSelect = (executive) => {
      setSelectedExecutive(executive);
      setSearchInput("");
    };
  
    const stages = [
      "Active",
      "Prospective",
      "Pipeline",
      "Dump",
      "Invalid",
      "Converted to Deals",
    ];
  
    const filteredStages = stages.filter((stage) =>
      stage.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    const handleStageSelect = (stage) => {
      setSelectedStage(stage);
      setSearchInput("");
    };
  
    return (
      <Box>
        <Navbar
          isSidebarExpanded={isSidebarExpanded}
          currentPage="Deal"
        />
        <Box
          position="absolute"
          top={1}
          left={leftMargin}
          transition="left 0.2s ease, width 0.2s ease"
          width={width}
          height={"auto"}
        >
          <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            position={"relative"}
            top={100}
          >
            <Flex p={3} mb={4}>
              <Heading
                color={"#151617"}
                as="h5"
                fontSize={"18px"}
                fontWeight={"bold"}
                fontFamily={"Public Sans, sans-serif"}
              >
                {`${userData.length} Deal Updates Found `}
              </Heading>
              <Box position={"absolute"} right={10}>
                <Flex>
                  <Flex
                    fontSize={"14px"}
                    border={"1px solid red"}
                    lineHeight={7}
                    borderRadius={8}
                    fontFamily={"Public Sans, sans-serif"}
                    color={"#D80000"}
                  >
                
                    <Text
                      borderRight={"1px solid #D80000"}
                      pl={4}
                      pr={4}
                      cursor={"pointer"}
                      _hover={{ bg: "#D8000026" }}
                      onClick={onOpen}
                    >
                      Filter
                    </Text>
                    <Text
                      pl={4}
                      pr={4}
                      cursor={"pointer"}
                      _hover={{ bg: "#D8000026" }}
                      onClick={handleOnClick}
                    >
                      Go Back
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            <TableContainer>
              <Table mt={4}>
                <Thead>
                  <Tr backgroundColor={"#4b4b4b"}>
                  <Th color={"white"}>S.No</Th>
                    <Th color={"white"}>Updated On</Th>
                    <Th color={"white"}>Date of addition</Th>   
                    <Th color={"white"}>Id</Th>
                    <Th color={"white"}>Name</Th>
                    <Th color={"white"}>Company Name</Th>
                    <Th color={"white"}>Contact No</Th>
                    <Th color={"white"}>Email</Th>
                    <Th color={"white"}>Executive</Th>
                    <Th color={"white"}>Update Message</Th>
                    <Th color={"white"}>Follow Up Date</Th>
                    <Th color={"white"}>Follow Up Time</Th>
                    <Th color={"white"}>Stage</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentUsers.map((user, index) => (
                    <Tr key={user.id}>
                      <Td>{(currentPage - 1) * rowsPerPage + index + 1}</Td>
                      <Td>{user.updateOn}</Td>
                      <Td>{user.dateOfAddition}</Td>
                      <Td>{user.id}</Td>
                      <Td>{user.name}</Td>
                      <Td>{user.companyName}</Td>
                      <Td>{user.contactNo}</Td>
                      <Td>{user.email}</Td>
                      <Td>{user.executive}</Td>
                      <Td>{user.updateMessage}</Td>
                      <Td>{user.followUpDate}</Td>
                      <Td>{user.followUpTime}</Td>
                      <Td>{user.stage}</Td>
                     
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex justifyContent="flex-end" mt={4} mb={4}>
              <Button
                onClick={() => goToPage(1)}
                isDisabled={currentPage === 1}
                mr={2}
              >
                First
              </Button>
              {[...Array(totalPages)].map((_, index) => (
                <Button
                  key={index}
                  onClick={() => goToPage(index + 1)}
                  mr={2}
                  colorScheme={currentPage === index + 1 ? "red" : "gray"}
                >
                  {index + 1}
                </Button>
              ))}
              
              <Button
                onClick={() => goToPage(totalPages)}
                isDisabled={currentPage === totalPages}
              >
                Last
              </Button>
            </Flex>
          </Box>
        </Box>
  
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size={"lg"}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Filter</DrawerHeader>
  
            <DrawerBody>
            <Grid templateColumns="repeat(2, 1fr)" w="100%">
                  <FormControl mb={2} isRequired>
                    <Input
                      type="email"
                      name="Email"
                      placeholder=" "
                      required
                    />
                  </FormControl>
    
                  <FormControl mb={2} ml={4}>
                  <FormLabel
                  fontSize={"0.9375rem"}
                  lineHeight={"1.47"}
                  mb={-0.2}
                  color={"#6f6b7d"}
                >
                </FormLabel>

                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDownOutline />}
                    width="100%"
                    textAlign="left"
                    color={"#6f6b7d"}
                    background={"none"}
                    border={"1px solid #dbdade"}
                    _hover={{ bg: "none" }}
                  >
                    {selectedStage ? selectedStage : "Select Stage"}
                  </MenuButton>
                  <MenuList maxHeight="200px" overflowY="auto">
                    <Box p={2}>
                      <Input
                        placeholder="Stage..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </Box>
                    {filteredStages.length > 0 ? (
                      filteredStages.map((stage, index) => (
                        <MenuItem
                          _hover={{
                            bg: "#D8000026",
                            textColor: "#D80000",
                          }}
                          key={index}
                          onClick={() => handleStageSelect(stage)}
                        >
                          {stage}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No matches found</MenuItem>
                    )}
                  </MenuList>
                </Menu>
                  </FormControl>
    
                  <FormControl mb={2} >
                  <FormLabel
                  fontSize={"0.9375rem"}
                  lineHeight={"1.47"}
                  mb={-0.2}
                  color={"#6f6b7d"}
                ></FormLabel>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDownOutline />}
                    width="100%"
                    textAlign="left"
                    color={"#6f6b7d"}
                    background={"none"}
                    border={"1px solid #dbdade"}
                    _hover={{ bg: "none" }}
                  >
                    {selectedExecutive ? selectedExecutive : "Select Executive"}
                  </MenuButton>
                  <MenuList maxHeight="200px" overflowY="auto">
                    <Box p={2}>
                      <Input
                        placeholder="Executive..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </Box>
                    {filteredExecutive.length > 0 ? (
                      filteredExecutive.map((executive, index) => (
                        <MenuItem
                          _hover={{
                            bg: "#D8000026",
                            textColor: "#D80000",
                          }}
                          key={index}
                          onClick={() => handleExecutiveSelect(executive)}
                        >
                          {executive}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No matches found</MenuItem>
                    )}
                  </MenuList>
                </Menu>
                  </FormControl>
                  
                  <FormControl  ml={4} mb={2}>
                <DatePicker
                  selected={selectedFromDate}
                  onChange={(date) => selectedFromDate(date)}
                  placeholderText="From Date"
                  customInput={<Input />}
                  dateFormat="MM/dd/yyyy"
                />
              </FormControl>
              <FormControl mb={2}>
                <DatePicker
                  selected={selectedToDate}
                  onChange={(date) => setSelectedToDate(date)}
                  placeholderText="To Date"
                  customInput={<Input />}
                  dateFormat="MM/dd/yyyy"
                />
              </FormControl>
                </Grid>
            </DrawerBody>
  
            <DrawerFooter>
            <Flex justify={"center"} width={"100%"}>
              <Button height={"30px"} width={"200px"} colorScheme="red">
                Filter
              </Button>
            </Flex>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Box>
    );
  };
  
  export default UpdateReport2;
