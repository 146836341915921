import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import Navbar from "../Sidebar/Navbar";
import { useNavigate } from "react-router-dom";

const Reports = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const Navigate = useNavigate();

  const handleOnClick = (e) => {
    e.preventDefault();
    Navigate("/overallReport");
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    Navigate("/updateReport");
  };
  const handleDetailedUpdateReport = (e) => {
    e.preventDefault();
    Navigate("/detailedUpdateReport");
  };
  const handleByStage = (e) => {
    e.preventDefault();
    Navigate("/byStage");
  };
  const handleByStatus = (e) => {
    e.preventDefault();
    Navigate("/byStatus");
  };

  const handleNotesReport = (e) => {
    e.preventDefault();
    Navigate("/notesReport");
  };

  const handleOverallReport2 = (e) => {
    e.preventDefault();
    Navigate("/overallReport2");
  };

  
  const handleUpdateReport2 = (e) => {
    e.preventDefault();
    Navigate("/updateReport2");
  };

  const handleByStage2 = (e) => {
    e.preventDefault();
    Navigate("/byStage2");
  };
  
  const handleByStatus2 = (e) => {
    e.preventDefault();
    Navigate("/byStatus2");
  };

  const handleOverallReport3 = (e) => {
    e.preventDefault();
    Navigate("/overallReport3");
  };

   
  const handleUpdateReport3 = (e) => {
    e.preventDefault();
    Navigate("/updateReport3");
  };
  const handleOverallReport4 = (e) => {
    e.preventDefault();
    Navigate("/overallReport4");
  };

    
  const handleUpdateReport4 = (e) => {
    e.preventDefault();
    Navigate("/updateReport4");
  };

  const handleIncomeReport = (e) => {
    e.preventDefault();
    Navigate("/incomereport")
   }
  
   const handleExpenseReport = (e) => {
    e.preventDefault();
    Navigate("/expensereport")
   }
   const handleProductsReport = (e) => {
    e.preventDefault();
    Navigate("/productsreport")
   }
   const handleOverallPurchaseReport = (e) => {
    e.preventDefault();
    Navigate("/overallpurchasereport")
   }
   const handlePurchaseReportByItem = (e) => {
    e.preventDefault();
    Navigate("/purchasereportbyitem")
   }
   const handleProjectTimeReport = (e) => {
    e.preventDefault();
    Navigate("/projecttimereport")
   }
  
   const handleActivity = (e) => {
    e.preventDefault();
    Navigate("/activityreport")
   }
   const handleUserCallLog = (e) => {
    e.preventDefault();
    Navigate("/usercalllogreport")
   }
   const handleTimeSheet = (e) => {
    e.preventDefault();
    Navigate("/timesheetreport")
   }
   const handleUsersReport = (e) => {
    e.preventDefault();
    Navigate("/usersreport")
   }
   const handleLocationTracking = (e) => {
    e.preventDefault();
    Navigate("/locationtracking")
   }
   const handleTimeSpent = (e) => {
    e.preventDefault();
    Navigate("/timespentreport")
   }
   const handleEmailLog = (e) => {
    e.preventDefault();
    Navigate("/emaillogreport")
   }
   const handleSmsLog = (e) => {
    e.preventDefault();
    Navigate("/smslogreport")
   }
   const handleWhatsAppLog = (e) => {
    e.preventDefault();
    Navigate("/whatsapplogreport")
   }
   const handleCommunicationCallLog = (e) => {
    e.preventDefault();
    Navigate("/communicationcalllogreport")
   }
   const handleSalesReport = (e) => {
    e.preventDefault();
    Navigate("/salesreport")
   }

  return (
    <>
      <Box>
        <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Reports" />
        <Box
          position="absolute"
          top={1}
          left={leftMargin}
          transition="left 0.2s ease, width 0.2s ease"
          width={width}
          height={"auto"}
        >
          <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            // p={3}
            borderRadius={7}
            position={"relative"}
            top={100}
            padding="25px 15px 15px 15px"
          >
            <Box>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                Active Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleOnClick}
                >
                  Overall Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleUpdate}
                >
                
                  Update Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleDetailedUpdateReport}
                >
                  Detailed Update Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleByStage}
                >
                  By Stage
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleByStatus}
                >
                  By Status
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleNotesReport}
                >
                  Notes Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                color={"#5D595C"}
                textAlign={"left"}
                fontWeight={600}
              >
                Deal Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleOverallReport2}
                >
                  Overall Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleUpdateReport2}
                >
                  Update Report
                </Link>

                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleByStage2}
                >
                  By Stage
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                 onClick={handleByStatus2}
                >
                  By Status
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                Client Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleOverallReport3}
                >
                  Overall Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleUpdateReport3}
                >
                  Update Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                Fresh Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleOverallReport4}
                >
                  Overall Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleUpdateReport4}
                >
                  Update Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                Communication Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleEmailLog}
                >
                  Email Log Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleSmsLog}
                >
                  SMS Log Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleWhatsAppLog}
                >
                  WhatsApp Log Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleCommunicationCallLog}
                >
                  Call Log Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleSalesReport}
                >
                  Sales Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                User Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleActivity}
                >
                  Activity Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleUserCallLog}
                >
                  Call Log Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleTimeSheet}
                >
                  Time Sheet Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleUsersReport}
                >
                  Users Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleLocationTracking}
                >
                  Location Tracking
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleTimeSpent}
                >
                  Time Spent Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                Other Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleIncomeReport}
                >
                  Income Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleExpenseReport}
                >
                  Expense Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleProductsReport}
                >
                  Products Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleOverallPurchaseReport}
                >
                  Purchase Order Overall Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handlePurchaseReportByItem}
                >
                  Purchase Order Report By Item
                </Link>
                <Link
                  pl={4}
                  textAlign={"left"}
                  color={"#7367f0"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  onClick={handleProjectTimeReport}
                >
                  Project Time Spent Report
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Reports;
