import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  Textarea,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  TabPanels,
  TabPanel,
  Tabs,
  TabList,
  Flex,
  Tab,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Navbar from "./Sidebar/Navbar";
import { IoChevronDownOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { TbAddressBook } from "react-icons/tb";
import { PiBuildings } from "react-icons/pi";

import Select from "react-select";
import countryList from "react-select-country-list";
import { FaDownload, FaIdCard, FaRegUser } from "react-icons/fa";

const AddUser = ({ isSidebarExpanded }) => {
  const [value, setValue] = React.useState(null);
  const options = countryList().getData();

  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";

  const [searchInput, setSearchInput] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");

  const [selectedRoles, setSelectedRoles] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedReporting, setSelectedReporting] = useState("");

  const navigate = useNavigate();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#D80000" : "black",
      backgroundColor: state.isFocused ? "#D8000026" : "white",
    }),
    // control: (provided) => ({
    //   ...provided,
    //   // borderColor: "#D80000",
    // }),
  };

  const handleChange = (value) => {
    setValue(value);
  };

  // Stages Dropdown

  const roles = [
    "Business Advisor",
    "Operation",
    "Sub Admin",
    "Team Lead",
    "VP",
  ];

  const filteredRoles = roles.filter((roles) =>
    roles.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleRolesSelect = (roles) => {
    setSelectedRoles(roles);
    setSearchInput("");
  };

  // Gender Dropdown

  const gender = ["Male", "Female", "Others"];

  const filteredGender = gender.filter((gender) =>
    gender.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setSearchInput("");
  };

  // Reporting Dropdown

  const reporting = ["Name 1", "Name 2", "Name 3", "Name 4"];

  const filteredReporting = reporting.filter((reporting) =>
    reporting.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleReportingSelect = (reporting) => {
    setSelectedGender(reporting);
    setSearchInput("");
  };

  //   const handleChange = (value) => {
  //     setValue(value);
  //   };

  const handleButton = (e) => {
    e.preventDefault();
    navigate("/users");
  };

  return (
    <Box>
      <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Add User" />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          p={3}
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Box>
            <Heading>
              <Button
                position={"absolute"}
                top={4}
                right={4}
                mb={10}
                border={"1px solid #D80000"}
                color={"#D80000"}
                height={"30px"}
                fontFamily={"Public Sans"}
                _hover={{ bg: "#D8000026" }}
                onClick={handleButton}
              >
                Go Back
              </Button>
            </Heading>
          </Box>
          {/* <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            height={"auto"}
          > */}
          <Tabs mt={"40px"}>
            <TabList w="100%">
              <Grid templateColumns="repeat(4, 1fr)" w="100%">
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  lineHeight={1.375}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <FaRegUser size={25} />
                    <Text fontSize={"0.9375rem"} ml={2}>
                      Personal
                    </Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <TbAddressBook size={25} />
                    <Text fontSize={"0.9375rem"} ml={2}>
                      Education
                    </Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <FaIdCard size={25} />
                    <Text fontSize={"0.9375rem"} ml={2}>
                      Employment
                    </Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <PiBuildings size={25} />
                    <Text fontSize={"0.9375rem"}>Official</Text>
                  </Flex>
                </Tab>
              </Grid>
            </TabList>

            <TabPanels p={4}>
              {/* Personal */}
              <TabPanel>
                <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      User ID :
                    </FormLabel>
                    <Input type="text" name="User ID" required />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      First Name :
                    </FormLabel>
                    <Input type="text" name="First Name" required />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Middle Name :
                    </FormLabel>
                    <Input type="text" name="Middle Name" required />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Last Name :
                    </FormLabel>
                    <Input type="text" name="Last Name" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Email Address :
                    </FormLabel>
                    <Input type="email" name="Email" required />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Contact Number :
                    </FormLabel>
                    <Input type="telephone" name="Contact" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Alternate Contact Number
                    </FormLabel>
                    <Input type="telephone" name="Contact" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel
                      fontSize={"0.9375rem"}
                      lineHeight={"1.47"}
                      mb={-0.2}
                      color={"#6f6b7d"}
                    >
                      Role / Designation:
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<IoChevronDownOutline />}
                        width="100%"
                        textAlign="left"
                        color={"#6f6b7d"}
                        background={"none"}
                        border={"1px solid #dbdade"}
                        _hover={{ bg: "none" }}
                      >
                        {selectedRoles ? selectedRoles : "Select value"}
                      </MenuButton>
                      <MenuList maxHeight="200px" overflowY="auto">
                        <Box p={2}>
                          <Input
                            placeholder="Roles..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </Box>
                        {filteredRoles.length > 0 ? (
                          filteredRoles.map((roles, index) => (
                            <MenuItem
                              _hover={{
                                bg: "#D8000026",
                                textColor: "#D80000",
                              }}
                              key={index}
                              onClick={() => handleRolesSelect(roles)}
                            >
                              {roles}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No matches found</MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Profile Picture :
                    </FormLabel>
                    <Input
                      type="file"
                      accept=".jpg, .jpeg, .webp"
                      name="Profile"
                      pt={1}
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel
                      fontSize={"0.9375rem"}
                      lineHeight={"1.47"}
                      mb={-0.2}
                      color={"#6f6b7d"}
                    >
                      Gender :
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<IoChevronDownOutline />}
                        width="100%"
                        textAlign="left"
                        color={"#6f6b7d"}
                        background={"none"}
                        border={"1px solid #dbdade"}
                        _hover={{ bg: "none" }}
                      >
                        {selectedGender ? selectedGender : "Select value"}
                      </MenuButton>
                      <MenuList maxHeight="200px" overflowY="auto">
                        <Box p={2}>
                          <Input
                            placeholder="Gender..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </Box>
                        {filteredGender.length > 0 ? (
                          filteredGender.map((gender, index) => (
                            <MenuItem
                              _hover={{
                                bg: "#D8000026",
                                textColor: "#D80000",
                              }}
                              key={index}
                              onClick={() => handleGenderSelect(gender)}
                            >
                              {gender}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No matches found</MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Date of Birth :
                    </FormLabel>
                    <Input type="date" name="DOB" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel
                      fontSize={"0.9375rem"}
                      lineHeight={"1.47"}
                      mb={-0.2}
                      color={"#6f6b7d"}
                    >
                      Reporting to :
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<IoChevronDownOutline />}
                        width="100%"
                        textAlign="left"
                        color={"#6f6b7d"}
                        background={"none"}
                        border={"1px solid #dbdade"}
                        _hover={{ bg: "none" }}
                      >
                        {selectedReporting ? selectedReporting : "Select value"}
                      </MenuButton>
                      <MenuList maxHeight="200px" overflowY="auto">
                        <Box p={2}>
                          <Input
                            placeholder="Reporting to..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </Box>
                        {filteredReporting.length > 0 ? (
                          filteredReporting.map((reporting, index) => (
                            <MenuItem
                              _hover={{
                                bg: "#D8000026",
                                textColor: "#D80000",
                              }}
                              key={index}
                              onClick={() => handleReportingSelect(reporting)}
                            >
                              {reporting}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No matches found</MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      State :
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel
                      fontSize={"0.9375rem"}
                      lineHeight={"1.47"}
                      mb={-0.2}
                      color={"#6f6b7d"}
                    >
                      Country:
                    </FormLabel>
                    <Select
                      options={options}
                      styles={customStyles}
                      maxMenuHeight={"200px"}
                      value={value}
                      onChange={handleChange}
                      placeholder="Select your country"
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Password :
                    </FormLabel>
                    <Input type="password" name="Password" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Confirm Password :
                    </FormLabel>
                    <Input type="password" name="Confirm Password" />
                  </FormControl>
                </Grid>

                <Grid templateColumns="repeat(1, 3fr)" gap={4}>
                  <Flex>
                    <FormControl mb={2} w={"30%"}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        Company Profile :
                      </FormLabel>
                      <Input type="text" name="Company Profile" />
                    </FormControl>

                    <Flex mt={8} ml={6}>
                      <Text fontWeight={"bold"}>Note: </Text>
                      <Text>
                        Email ID & Password will be used for User Login
                      </Text>
                    </Flex>
                  </Flex>
                </Grid>

                <Flex justifyContent={"flex-end"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={2}
                  >
                    Add User
                  </Button>
                </Flex>
              </TabPanel>

              {/* Educational */}
              <TabPanel>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Degree / Qualification :
                    </FormLabel>
                    <Input type="text" name="Degree" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Certificate / Document (If any) :
                    </FormLabel>
                    <Input
                      type="file"
                      accept=".pdf, .word"
                      pt={1}
                      name="Document"
                    />
                  </FormControl>
                </Grid>
                <Flex justifyContent={"flex-end"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={2}
                  >
                    Update User
                  </Button>
                </Flex>
              </TabPanel>

              {/* Employment */}
              <TabPanel>
                <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Company Name :
                    </FormLabel>
                    <Input type="text" name="Company" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Experience :
                    </FormLabel>
                    <Input type="text" name="Experience" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Document Detail :
                    </FormLabel>
                    <Input type="text" name="Document" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Upload Document (If any) :
                    </FormLabel>
                    <Input
                      type="file"
                      accept=".pdf, .word"
                      pt={1}
                      name="Document"
                    />
                  </FormControl>
                </Grid>
                <Flex justifyContent={"flex-end"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={2}
                  >
                    Update User
                  </Button>
                </Flex>
              </TabPanel>

              {/* Official */}
              <TabPanel>
                <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Offer Letter :
                    </FormLabel>
                    <Input
                      type="file"
                      accept=".pdf, .word"
                      pt={1}
                      name="Document"
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Attached Offer Letter :
                    </FormLabel>
                    <Flex justifyContent="flex-start">
                      <Button
                        color={"white"}
                        background={"#ff9f43"}
                        height={"30px"}
                        mt={2}
                        ml={2}
                      >
                        <FaDownload />
                        No attachment
                      </Button>
                    </Flex>
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Appointment Letter :
                    </FormLabel>
                    <Input
                      type="file"
                      accept=".pdf, .word"
                      pt={1}
                      name="Appointment"
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Attached Appointment Letter :
                    </FormLabel>
                    <Flex justifyContent="flex-start">
                      <Button
                        color={"white"}
                        background={"#ff9f43"}
                        height={"30px"}
                        mt={2}
                        ml={2}
                      >
                        <FaDownload />
                        No attachment
                      </Button>
                    </Flex>
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Date of Joining :
                    </FormLabel>
                    <Input type="date" name="Date of Joining" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Salary Details :
                    </FormLabel>
                    <Input type="number" name="Date of Joining" value="0.00" />
                  </FormControl>
                </Grid>
                <Flex justifyContent={"flex-end"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={2}
                  >
                    Update User
                  </Button>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Box>
    // </Box>
  );
};

export default AddUser;
