import {
    Box,
    Button,
    Center,
    Checkbox,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Heading,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Select,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
  } from "@chakra-ui/react";
  import Navbar from "../../Sidebar/Navbar";
  import { useNavigate } from "react-router-dom";
  import React, { useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiFileText, FiPrinter } from "react-icons/fi";
 
   
  const UsersReport = ({ isSidebarExpanded }) => {
    const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
    const leftMargin = isSidebarExpanded ? "270px" : "110px";
    const navigate = useNavigate();
   
    const handleOnClick = (e) => {
      e.preventDefault();
      navigate("/reports");
    };

   
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
   
    return (
      <Box>
        <Navbar
          isSidebarExpanded={isSidebarExpanded}
          currentPage="Users report"
        />
        <Box
          position="absolute"
          top={1}
          left={leftMargin}
          transition="left 0.2s ease, width 0.2s ease"
          width={width}
          height={"auto"}
        >
          <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            position={"relative"}
            top={100}
          >
            <Flex p={3} mb={4}>
              <Heading
                color={"#151617"}
                as="h5"
                fontSize={"18px"}
                fontWeight={"bold"}
                fontFamily={"Public Sans, sans-serif"}
                mt={2}
              >
                Users
              </Heading>
              <Box position={"absolute"} right={10}>
                <Flex>
                  <Flex
                    fontSize={"14px"}
                    border={"1px solid red"}
                    lineHeight={7}
                    borderRadius={8}
                    fontFamily={"Public Sans, sans-serif"}
                    color={"#D80000"}
                  >
                  <Menu>
                    <MenuButton
                      rightIcon={<ChevronDownIcon />}
                      borderRight={"1px solid #D80000"}
                      pl={4}
                      pr={4}
                      cursor={"pointer"}
                      _hover={{ bg: "#D8000026" }}
                    >
                      Export
                    </MenuButton>
                    <MenuList minW="140px">
                      <MenuItem color={"black"} icon={<FiPrinter />}>
                        Print
                      </MenuItem>
                      <MenuItem color={"black"} icon={<FiFileText />}>
                        CSV
                      </MenuItem>
                    </MenuList>
                  </Menu>
                    <Text
                      pl={4}
                      pr={4}
                      cursor={"pointer"}
                      _hover={{ bg: "#D8000026" }}
                      onClick={handleOnClick}
                    >
                      Go Back
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            <TableContainer>
              <Table mt={4}>
                <Thead>
                  <Tr backgroundColor={"#4b4b4b"}>
                    <Th color={"white"}>S.No</Th>
                    <Th color={"white"}>ID</Th>
                    <Th color={"white"}>Name</Th>
                    <Th color={"white"}>Role</Th>
                    <Th color={"white"}>Phone</Th>
                    <Th color={"white"}>Email Address</Th>
                    <Th color={"white"}>Reporting to</Th>
                    <Th color={"white"}>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                      <Td>1</Td>
                      <Td>1234</Td>
                      <Td>test</Td>
                      <Td>testing</Td>
                      <Td>1234567890</Td>
                      <Td>abc@gmail.com</Td>
                      <Td></Td>
                      <Td>Active</Td>
                    </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
            size={"lg"}
          >
            <DrawerOverlay />
            <DrawerContent w="800px">
              <DrawerCloseButton />
              <DrawerHeader>Filter</DrawerHeader>
   
              <DrawerBody>
                <Grid templateColumns="repeat(2, 1fr)" gap={4} w="100%">
                  <FormControl mb={2} isRequired>
                    <Input
                      type="email"
                      name="Email"
                      placeholder=" "
                      required
                    />
                  </FormControl>
                  <FormControl mb={2} isRequired>
                    <Input type="text" placeholder="Search Term" required />
                  </FormControl>
                  <FormControl mb={2}>
                    <Input type="text" placeholder="Select Stage"  />
                  </FormControl>
                  <FormControl mb={2} isRequired>
                    <Input type="text" placeholder="Select Status"  />
                  </FormControl>
                  <FormControl mb={2} isRequired>
                    <Input type="text" placeholder="Select Source" />
                  </FormControl>
                </Grid>
              </DrawerBody>
   
              <DrawerFooter>
                <Button width={"100%"} height={7} mr={"7"} colorScheme="red">
                  Filter
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Box>
      </Box>
    );
  };
   
  export default UsersReport;