import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import Navbar from "./Sidebar/Navbar";
import React, { useState } from "react";
import {
  FaDownload,
  FaIdCard,
  FaRegUser,
  FaUserCircle,
} from "react-icons/fa";
import {
  TbAddressBook,
  TbBook,
  TbBriefcase,
  TbBuilding,
  TbUser,
} from "react-icons/tb";
import { RiKey2Line } from "react-icons/ri";
import { FiFacebook } from "react-icons/fi";
import { PiBuildings, PiLockOpen } from "react-icons/pi";
import Select from "react-select";
import countryList from "react-select-country-list";
import { Navigate, useNavigate } from "react-router-dom";
import { IoChevronDownOutline } from "react-icons/io5";
import { DownloadIcon } from "@chakra-ui/icons";

const EditUser = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const options = countryList().getData();

  const navigate = useNavigate();
  const [value, setValue] = React.useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedRoles, setSelectedRoles] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const toast = useToast();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#D80000" : "black",
      backgroundColor: state.isFocused ? "#D8000026" : "white",
    }),
    indicatorSeparator: () => ({
        display: 'none', 
      }),
  };

  const handleChange = (value) => {
    setValue(value);
  };

  const handleGoBack = () => {
    navigate("/users"); 
  };

  const roles = ["Business Adviser", "Operation", "Sub Admin", "VP"];

  const filteredRoles = roles.filter((roles) =>
    roles.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleRolesSelect = (roles) => {
    setSelectedRoles(roles);
    setSearchInput("");
  };
    const handleUpdateUser = () => {
      console.log("User updated!");

      toast({
        description: "user has been updated successfully.",
        status: "success", 
        duration: 3000, 
        isClosable: true,
      });
      
    };

  const gender = ["Male", "Female"];
  const filteredGender = gender.filter((gender) =>
    gender.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setSearchInput("");
  };
   
  return (
    <Box>
      <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Edit User" />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          p={3}
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Box>
            <Heading>
              <Button
                position={"absolute"}
                top={4}
                right={4}
                mb={10}
                border={"1px solid #D80000"}
                color={"#D80000"}
                height={"30px"}
                fontFamily={"Public Sans"}
                _hover={{ bg: "#D8000026" }}
                onClick={handleGoBack}
              >
                Go Back
              </Button>
            </Heading>
          </Box>
          <Tabs mt={"40px"}>
            <TabList w="100%">
              <Grid templateColumns="repeat(4, 1fr)" w="100%">
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  lineHeight={1.375}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <FaRegUser size={25} />
                    <Text fontSize={"0.9375rem"} ml={2}>
                      Personal
                    </Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <TbAddressBook size={25} />
                    <Text fontSize={"0.9375rem"} ml={2}>
                      Education
                    </Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <FaIdCard size={25} />
                    <Text fontSize={"0.9375rem"} ml={2}>
                      Employment
                    </Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <PiBuildings size={25} />
                    <Text fontSize={"0.9375rem"}>Official</Text>
                  </Flex>
                </Tab>
              </Grid>
            </TabList>

            <TabPanels p={4}>
              {/* Contact Information TabPanel */}
              <TabPanel>
                <Grid mt={10} templateColumns="repeat(4, 1fr)" gap={4}>
                  <FormControl mb={2} isRequired>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      User ID:
                    </FormLabel>
                    <Input type="email" name="Email" required />
                  </FormControl>
                  <FormControl mb={2} isRequired>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      First Name :
                    </FormLabel>
                    <Input type="text" name="First Name" required />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Middle Name :
                    </FormLabel>
                    <Input type="text" name="Last Name" />
                  </FormControl>
                  <FormControl mb={2} isRequired>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Last Name :
                    </FormLabel>
                    <Input type="text" name="Last Name" />
                  </FormControl>
                  <FormControl mb={2} isRequired>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Email Address :
                    </FormLabel>
                    <Input type="telephone" name="Contact" />
                  </FormControl>
                  <FormControl mb={2} isRequired>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Phone Number:
                    </FormLabel>
                    <Input
                      type="telephone"
                      name="Contact"
                      placeholder="Enter your Alternate Contact"
                    />
                  </FormControl>
                  <FormControl mb={2} isRequired>
                    <FormLabel
                      fontSize={"0.9375rem"}
                      lineHeight={"1.47"}
                      mb={-0.2}
                      color={"#6f6b7d"}
                    >
                      Role / Designation :
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<IoChevronDownOutline />}
                        width="100%"
                        textAlign="left"
                        color={"#6f6b7d"}
                        background={"none"}
                        border={"1px solid #dbdade"}
                        _hover={{ bg: "none" }}
                      >
                        {selectedRoles ? selectedRoles : "Select Roles"}
                      </MenuButton>
                      <MenuList maxHeight="200px" overflowY="auto">
                        <Box p={2}>
                          <Input
                            placeholder="Roles..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </Box>
                        {filteredRoles.length > 0 ? (
                          filteredRoles.map((roles, index) => (
                            <MenuItem
                              _hover={{
                                bg: "#D8000026",
                                textColor: "#D80000",
                              }}
                              key={index}
                              onClick={() => handleRolesSelect(roles)}
                            >
                              {roles}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No matches found</MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Reporting to:
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel
                      fontSize={"0.9375rem"}
                      lineHeight={"1.47"}
                      mb={-0.1}
                      color={"#6f6b7d"}
                    >
                      Alternate Contact No:
                    </FormLabel>

                    <Input type="text" name="State" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Gender :
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<IoChevronDownOutline />}
                        width="100%"
                        textAlign="left"
                        color={"#6f6b7d"}
                        background={"none"}
                        border={"1px solid #dbdade"}
                        _hover={{ bg: "none" }}
                      >
                        {selectedGender ? selectedGender : "Select Gender"}
                      </MenuButton>
                      <MenuList maxHeight="200px" overflowY="auto">
                        <Box p={2}>
                          <Input
                            placeholder="Gender..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </Box>
                        {filteredGender.length > 0 ? (
                          filteredGender.map((gender, index) => (
                            <MenuItem
                              _hover={{
                                bg: "#D8000026",
                                textColor: "#D80000",
                              }}
                              key={index}
                              onClick={() => handleGenderSelect(gender)}
                            >
                              {gender}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No matches found</MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Date of Birth :
                    </FormLabel>
                    <Input type="date" name="State" />
                  </FormControl>
                  <Box />

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Profile Picture :
                    </FormLabel>
                    <Input
                      pt={1}
                      type="file"
                      accept=".jpg, .jpeg, .webp"
                      name="Profile"
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Profile Picture Preview:
                    </FormLabel>
                    <FaUserCircle size={100} color={"#6f6b7d"} />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      State :
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Country:
                    </FormLabel>
                    <Select
                      options={options}
                      styles={customStyles}
                      maxMenuHeight={"200px"}
                      value={value}
                      onChange={handleChange}
                      placeholder="Select your country"
                    />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Password :
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Confirm Password :
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>
                  <Flex flexDirection={"row"} ml={6} mt={8}>
                    <Checkbox alignItems={"top"} mt={1} mr={2} />
                    <Text>Location Access</Text>
                  </Flex>
                  <Flex flexDirection={"row"} mt={8}>
                    <Checkbox alignItems={"top"} mt={1} mr={2} />
                    <Text>Disable Mobile App Login</Text>
                  </Flex>
                </Grid>

                <Flex justifyContent={"flex-end"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={2}
                    onClick={handleUpdateUser}
                  >
                    Update User
                  </Button>
                </Flex>
              </TabPanel>
              <TabPanel>
                <Grid mt={10} templateColumns="repeat(2, 1fr)" gap={4}>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Degree / Qualification :
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Certificate / Document (If any) :
                    </FormLabel>
                    <Input pt={1} type="file" accept=".pdf" name="File" />
                  </FormControl>
                  <Box />
                </Grid>
                <Flex justifyContent={"flex-end"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={2}
                    onClick={handleUpdateUser}
                  >
                    Update User
                  </Button>
                </Flex>
              </TabPanel>
              <TabPanel>
                <Grid mt={10} templateColumns="repeat(4, 1fr)" gap={4}>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Company Name :
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Experience :
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Document Detail :
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Upload Document(If any) :
                    </FormLabel>
                    <Input pt={1} type="file" accept=".pdf" name="File" />
                  </FormControl>
                </Grid>
                <Flex justifyContent={"flex-end"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={2}
                    onClick={handleUpdateUser}
                  >
                    Update User
                  </Button>
                </Flex>
              </TabPanel>
              <TabPanel>
                <Grid mt={10} templateColumns="repeat(4, 1fr)" gap={4}>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Offer Letter :
                    </FormLabel>
                    <Input pt={1} type="file" accept=".pdf" name="File" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Attached Offer Letter :
                    </FormLabel>
                    <Flex justifyContent="flex-start">
                      <Button
                        color={"white"}
                        background={"#ff9f43"}
                        height={"30px"}
                        mt={2}
                        ml={2}
                      >
                        <FaDownload />
                        No Attachment
                      </Button>
                    </Flex>
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Appointment Letter :
                    </FormLabel>
                    <Input pt={1} type="file" accept=".pdf" name="File" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Attached Appointment Letter:
                    </FormLabel>
                    <Flex justifyContent="flex-start">
                      <Button
                        color={"white"}
                        background={"#ff9f43"}
                        height={"30px"}
                        mt={2}
                        ml={2}
                      >
                        <FaDownload />
                        No Attachment
                      </Button>
                    </Flex>
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Date of Joining :
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                      Salary Details :
                    </FormLabel>
                    <Input type="text" name="State" />
                  </FormControl>
                </Grid>
                <Flex justifyContent={"flex-end"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={2}
                    onClick={handleUpdateUser}
                  >
                    Update User
                  </Button>
                </Flex>
              </TabPanel>

              <TabPanel>
                <Text textAlign={"left"} mb={6}>
                  Authentication Type
                </Text>
                <Flex flexDirection={"row"} alignItems="center">
                  <Checkbox alignItems={"top"} mt={1} mr={2} />
                  <Text>Email OTP</Text>

                  <FormControl ml={4} width="auto">
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Email Verification OTP
                    </FormLabel>
                    <Input type="text" placeholder="Enter OTP" />
                  </FormControl>
                </Flex>
                <Flex justifyContent={"flex-end"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={2}
                  >
                    Save
                  </Button>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

export default EditUser;
