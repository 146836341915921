import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  FormControl,
  Input,
  Button,
  Center,
  Icon,
  useToast,
  Heading,
  Flex,
  Text,
  Image,
  InputGroup,
  InputRightElement,
  IconButton,
  Link,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import BgImg from "../Images/BgImg.png";
import Logo from "../Images/logo-img.png";
import Bg from "../Images/bg.png";
import Particle from "../Login/Particle";
import {useDispatch, useSelector} from "react-redux"
import { signin } from "../../Redux/AuthReducer/Action";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [employeeid, setEmployeeid] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token)

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Add your authentication logic here
    const user = {
      email : employeeid,
      password: password

    }

    dispatch((signin(user,toast,navigate)))

    // If authentication is successful, redirect to the home page
    // navigate("/dashboard");
  };

  return (
    <Box
      position="relative"
      width="100%"
      height="100vh"
      margin="auto"
      backgroundColor="#fff"
      backgroundImage={`url(${Bg})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      border="2px solid #f3f3f3"
      backgroundPosition="50% 50%"
    >
      <Particle />
      <Flex ml={260} mr={260} mt={90} height="410px" zIndex={1} position="relative">
        <Box
          width="400px"
          height="430px"
          backgroundImage={`url(${BgImg})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          borderTopLeftRadius={12}
          borderBottomLeftRadius={12}
          boxShadow="rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
        >
          <Box margin="auto" mt="180px" height="50px">
            <Heading
              fontSize={18}
              color="#fff"
              letterSpacing="0.5px"
              fontFamily="Sans-serif"
              mt={0}
              mb="0.5rem"
              fontWeight={700}
              lineHeight={1.2}
              textAlign = {"center"}
            >
              UNLOCK YOUR BUSINESS POTENTIAL
              <Heading
                color="#fff"
                fontSize="1rem"
                mt={0}
                mb=".5rem"
                fontWeight={500}
                lineHeight={1.2}
              >
                Simplify, Streamline, Succeed!
              </Heading>
            </Heading>
          </Box>
        </Box>
        <Box
          width="420px"
          justifyContent="center"
          alignItems="center"
          height="430px"
          borderTopRightRadius={12}
          borderBottomRightRadius={12}
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          backgroundColor="white"
          zIndex={2}
          position="relative"
        >
          <Box width="420px" p={6}>
            <Text mb={4} fontWeight="bold" textAlign="center">
              Welcome to
            </Text>
            <Center mb={4}>
              <Image src={Logo} alt="Logo" width="200px" />
            </Center>
            <FormControl id="employeeid" isRequired>
              <InputGroup>
                <Input
                  type="text"
                  height="50px"
                  placeholder="Enter Email Address"
                  onChange={(e) => setEmployeeid(e.target.value)}
                  focusBorderColor="red.500"
                  borderColor="red.500"
                  borderWidth="2px"
                  borderRadius="40px"
                />
                <InputRightElement width="4.5rem">
                  <Icon as={FaUser} color="gray.500" mt={2} />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="password" mt={4} isRequired>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  height="50px"
                  borderRadius="40px"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  focusBorderColor="red.500"
                  borderColor="red.500"
                  borderWidth="2px"
                />
                <InputRightElement width="4.5rem">
                  <IconButton
                    h="1.75rem"
                    size="sm"
                    mt={3}
                    onClick={handleTogglePassword}
                    icon={
                      showPassword ? (
                        <Icon as={ViewOffIcon} />
                      ) : (
                        <Icon as={ViewIcon} />
                      )
                    }
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="otp" mt={4}>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  height="50px"
                  placeholder="Enter MFA"
                  focusBorderColor="red.500"
                  borderColor="red.500"
                  borderWidth="2px"
                  borderRadius="40px"
                />
              </InputGroup>
            </FormControl>

            <Button
              backgroundColor="red.500"
              color="#fff"
              mt={6}
              width="full"
              borderRadius="40px"
              onClick={handleLogin}
            >
              Login
            </Button>
            <Flex mt={4} justifyContent="space-between">
              <Text>
                <Link color="blue.500">Reset password</Link>
              </Text>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Login;
