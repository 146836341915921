import {
  Box,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Navbar from "../../Sidebar/Navbar";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

const LocationTracking = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/reports");
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Live Location"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Flex p={3} mb={4}>
            <Heading
              color={"#151617"}
              as="h5"
              fontSize={"18px"}
              fontWeight={"bold"}
              fontFamily={"Public Sans, sans-serif"}
              mt={2}
            >
              Live Locations
            </Heading>
            <Box position={"absolute"} right={10}>
              <Flex>
                <Flex
                  fontSize={"14px"}
                  border={"1px solid red"}
                  lineHeight={7}
                  borderRadius={8}
                  fontFamily={"Public Sans, sans-serif"}
                  color={"#D80000"}
                >
                  <Text
                    borderRight={"1px solid #D80000"}
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}
                    onClick={onOpen}
                  >
                    Refresh
                  </Text>
                  <Text
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}  
                    onClick={handleOnClick}
                  >
                    Go Back
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>

        </Box>
      </Box>
    </Box>
  );
};

export default LocationTracking;
