import React, { useState } from 'react'
import Login from '../Components/Login/Login';
import { Route, Routes, Navigate  } from 'react-router-dom';
import Home from '../Components/Home/Home.jsx';
import Sidebar from '../Components/Sidebar/Sidebar.js';
import Navbar from '../Components/Sidebar/Navbar.js';
// import Enquiries from '../Components/Enquiries.js';
import Customers from '../Components/Customers.js';
// import Finance from '../Components/Finance.js';
import Projects from '../Components/Projects.js';
import Report from '../Components/Report/Reports.js';
import Users from '../Components/Users.js';
// import LoginHistory from '../Components/LoginHistory.js';
import Fresh from '../Components/Fresh.js';
import AddNewLead from '../Components/AddNewLead.js';
import AddNewFresh from '../Components/AddNewFresh.js';
import ProposalShared from '../Components/ProposalShared.js';
import Active from '../Components/Active.js';
import Prospective from '../Components/Prospective.js';
import Pipeline from '../Components/Pipeline.js';
import AddNewProposal from '../Components/AddNewProposal.js';
import AddNewActive from '../Components/AddNewActive.js';
import AddNewProspective from '../Components/AddNewProspective.js';
import AddNewPipeline from '../Components/AddNewPipeline.js';
import AddNewProjects from '../Components/AddNewProjects.js';
import AddNewCustomers from '../Components/AddNewCustomers.js';
import Income from '../Components/Income.js';
// import Expense from '../Components/Statistics.js';
import AddIncome from '../Components/AddIncome.js';
import AddExpense from '../Components/AddExpense.js';
import Settings from '../Components/Settings/Settings.js';
import Dump from '../Components/Dump.js';
import MyProfile from '../Components/Profile/MyProfile.js';
import Reports from '../Components/Report/Reports.js';
import AddUser from '../Components/AddUser.js';
import EditUser from '../Components/EditUser.js';
import OverallReport from '../Components/Report/ActiveReports/OverallReport.js';
import UpdateReport from '../Components/Report/ActiveReports/UpdateReport.js';
import DetailedUpdateReport from '../Components/Report/ActiveReports/DetailedUpdateReport.js';
import ByStage from '../Components/Report/ActiveReports/ByStage.js';
import NotesReport from '../Components/Report/ActiveReports/NotesReport.js';
import ByStatus from '../Components/Report/ActiveReports/ByStatus.js';
import OverallReport2 from '../Components/Report/DealReports/OverallReport2.js';
import UpdateReport2 from '../Components/Report/DealReports/UpdateReport2.js';
import ByStage2 from '../Components/Report/DealReports/ByStage2.js';
import ByStatus2 from '../Components/Report/DealReports/ByStatus2.js';
import OverallReport3 from '../Components/Report/ClientReports/OverallReport3.js';
import UpdateReport3 from '../Components/Report/ClientReports/UpdateReport3.js';
import OverallReport4 from '../Components/Report/FreshReports/OverallReport4.js';
import UpdateReport4 from '../Components/Report/FreshReports/UpdateReport4.js';

import IncomeReport from '../Components/Report/Other Reports/IncomeReport.js';
import ExpenseReport from '../Components/Report/Other Reports/ExpenseReport.js';
import ProductsReport from '../Components/Report/Other Reports/ProductsReport.js';
import PurchaseReport from '../Components/Report/Other Reports/OverallPurchaseReport.js';
import PurchaseReportByItem from '../Components/Report/Other Reports/PurchaseReportByItem.js';
import ProjectTimeReport from '../Components/Report/Other Reports/ProjectTimeReport.js';


import ActivityReport from '../Components/Report/User Reports/ActivityReport.js';
import UserCallLogReport from '../Components/Report/User Reports/UserCallLogReport.js';
import TimeSheetReport from '../Components/Report/User Reports/TimeSheetReport.js';
import UsersReport from '../Components/Report/User Reports/UsersReport.js';
import LocationTracking from '../Components/Report/User Reports/LocationTracking.js';
import TimeSpentReport from '../Components/Report/User Reports/TimeSpentReport.js';

import EmailLogReport from '../Components/Report/Communication Reports/EmailLogReport.js';
import SmsLogReport from '../Components/Report/Communication Reports/SmsLogReport.js';
import SalesReport from '../Components/Report/Communication Reports/SalesReport.js';
import CommunicationCallLogReport from '../Components/Report/Communication Reports/CommunicationCallLogReport.js';
import WhatsAppLogReport from '../Components/Report/Communication Reports/WhatsAppLogReport.js';
import Approvals from '../Components/Approvals.js';
import CompanyProfile from '../Components/Profile/CompanyProfile.js';
import Statistics from '../Components/Statistics.js';






const AllRoutes = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const handleSidebarChange = (isExpanded) => {
    setIsSidebarExpanded(isExpanded);
  };
  return (
    <Routes>
      <Route path='/login' element={<><Login /></>} />
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path='navbar' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Navbar isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/dashboard' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Home isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewlead' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewLead isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/fresh' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Fresh isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/proposal-shared' element={<><Sidebar onSidebarChange={handleSidebarChange} /><ProposalShared isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/active' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Active isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/prospective' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Prospective isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/pipeline' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Pipeline isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/dump' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Dump isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/projects' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Projects isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/customers' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Customers isSidebarExpanded={isSidebarExpanded} /></>} />
      {/* <Route path='/finance' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Finance isSidebarExpanded={isSidebarExpanded} /></>} /> */}
      <Route path='/report' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Report isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/users' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Users isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewfresh' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewFresh isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewproposal' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewProposal isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewactive' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewActive isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewprospective' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewProspective isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewpipeline' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewPipeline isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewprojects' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewProjects isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addcustomer' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewCustomers isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/income' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Income isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addIncome' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddIncome isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/statistics' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Statistics isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addExpense' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddExpense isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/approvals' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Approvals isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/reports' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Reports isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/adduser' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddUser isSidebarExpanded={isSidebarExpanded} /></>} />
     
      <Route path='/settings' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Settings isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/myprofile' element={<><Sidebar onSidebarChange={handleSidebarChange} /><MyProfile isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/companyprofile' element={<><Sidebar onSidebarChange={handleSidebarChange} /><CompanyProfile isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/editUser' element={<><Sidebar onSidebarChange={handleSidebarChange} /><EditUser isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/overallReport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><OverallReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/updateReport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><UpdateReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/detailedUpdateReport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><DetailedUpdateReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/byStage' element={<><Sidebar onSidebarChange={handleSidebarChange} /><ByStage isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/byStatus' element={<><Sidebar onSidebarChange={handleSidebarChange} /><ByStatus isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/notesReport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><NotesReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/overallReport2' element={<><Sidebar onSidebarChange={handleSidebarChange} /><OverallReport2 isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/updateReport2' element={<><Sidebar onSidebarChange={handleSidebarChange} /><UpdateReport2 isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/byStage2' element={<><Sidebar onSidebarChange={handleSidebarChange} /><ByStage2 isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/byStatus2' element={<><Sidebar onSidebarChange={handleSidebarChange} /><ByStatus2 isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/overallReport3' element={<><Sidebar onSidebarChange={handleSidebarChange} /><OverallReport3 isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/updateReport3' element={<><Sidebar onSidebarChange={handleSidebarChange} /><UpdateReport3 isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/overallReport4' element={<><Sidebar onSidebarChange={handleSidebarChange} /><OverallReport4 isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/updateReport4' element={<><Sidebar onSidebarChange={handleSidebarChange} /><UpdateReport4 isSidebarExpanded={isSidebarExpanded} /></>} />
      
      
      <Route path='/incomereport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><IncomeReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/expensereport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><ExpenseReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/productsreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><ProductsReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/overallpurchasereport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><PurchaseReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/purchasereportbyitem' element={<><Sidebar onSidebarChange={handleSidebarChange} /><PurchaseReportByItem isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/projecttimereport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><ProjectTimeReport isSidebarExpanded={isSidebarExpanded} /></>} />

      <Route path='/activityreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><ActivityReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/usercalllogreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><UserCallLogReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/timesheetreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><TimeSheetReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/usersreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><UsersReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/locationtracking' element={<><Sidebar onSidebarChange={handleSidebarChange} /><LocationTracking isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/timespentreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><TimeSpentReport isSidebarExpanded={isSidebarExpanded} /></>} />

      <Route path='/emaillogreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><EmailLogReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/smslogreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><SmsLogReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/whatsapplogreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><WhatsAppLogReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/salesreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><SalesReport isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/communicationcalllogreport' element={<><Sidebar onSidebarChange={handleSidebarChange} /><CommunicationCallLogReport isSidebarExpanded={isSidebarExpanded} /></>} />



      {/* <Route path='/loginhistory' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Navbar isSidebarExpanded={isSidebarExpanded} currentPage="LoginHistory" /><LoginHistory isSidebarExpanded={isSidebarExpanded} /></>} /> */}
      
    </Routes>
  ) 
}

export default AllRoutes