import * as types from "./ActionTypes";
import axios from "axios";
import { Reducer } from "../AuthReducer/Reducer";


//AddNewLeads
export const AddNewLeads = (token, formData) => async (dispatch) => {
    dispatch({ type: types.ADDNEWLEADS_REQUEST });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/addnewlead`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.ADDNEWLEADS_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.ADDNEWLEADS_FAILURE });
    }
  };

  //Get All New Lead 
  export const GetAllLeads = (token) => async (dispatch) => {
    dispatch({ type: types.GETALLLEADS_REQUEST });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getAllnewLeads`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.GETALLLEADS_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.GETALLLEADS_FAILURE });
    }
  };

  //Lead Status
  export const LeadStatus = (token, LeadID, action) => async (dispatch) => {
    dispatch({ type: types.LEADSTATUS_REQUEST });
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/lead/${LeadID}`,
     { status: action },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
      const data = response.data;
      dispatch({ type: types.LEADSTATUS_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.LEADSTATUS_FAILURE });
    }
  };

  //Get Proposal Shared
  export const GetProposal = (token) => async (dispatch) => {
    dispatch({ type: types.GETPROPOSAL_REQUEST });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getProposalShared`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.GETPROPOSAL_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.GETPROPOSAL_FAILURE });
    }
  };

  //Get Active Leads
  export const GetActive = (token) => async (dispatch) => {
    dispatch({ type: types.GETACTIVE_REQUEST });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getactiveleads`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.GETACTIVE_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.GETACTIVE_FAILURE });
    }
  };

  //Get Prospective
  export const GetProspective = (token) => async (dispatch) => {
    dispatch({ type: types.GETPROSPECTIVE_REQUEST });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getProspective`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.GETPROSPECTIVE_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.GETPROSPECTIVE_FAILURE });
    }
  };

  //Get All Pipeline
  export const GetPipeline = (token) => async (dispatch) => {
    dispatch({ type: types.GETPIPELINE_REQUEST });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getPipeline`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.GETPIPELINE_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.GETPIPELINE_FAILURE });
    }
  };


  //Get All Dump
  export const GetDump = (token) => async (dispatch) => {
    dispatch({ type: types.GETDUMP_REQUEST });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getDump`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.GETDUMP_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.GETDUMP_FAILURE });
    }
  };

  //Get All Project
  export const GetProject = (token) => async (dispatch) => {
    dispatch({ type: types.GETPROJECT_REQUEST });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getprojects`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.GETPROJECT_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.GETPROJECT_FAILURE });
    }
  };

  //Get Customers

  export const GetCustomers = (token) => async (dispatch) => {
    dispatch({ type: types.GETCUSTOMERS_REQUEST });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getCustomers`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.GETCUSTOMERS_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.GETCUSTOMERS_FAILURE });
    }
  };

  //Get review(Approval finance)

  export const GetReview = (token) => async (dispatch) => {
    dispatch({ type: types.GETREVIEW_REQUEST });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getreview`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.GETREVIEW_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.GETREVIEW_FAILURE });
    }
  };

  //Edit Fresh Page Lead
  export const EditLeads = (token, LeadID, updatedLeadData) => async (dispatch) => {
    dispatch({ type: types.EDITLEADS_REQUEST });
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/lead/${LeadID}/edit`,
        updatedLeadData,  
        {
          headers: {
            Authorization: `${token}`, 
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.EDITLEADS_SUCCESS, payload: data });
      console.log( data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.EDITLEADS_FAILURE });
    }
  };

  // Add Description on Project Page(Project Details)
  export const SaveDescription = (token, leadId, description) => async (dispatch) => {
    dispatch({ type: types.SAVEDESCRIPTION_REQUEST });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/addDescription`,
        { leadId, description },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      
      const data = response.data;
      dispatch({ type: types.SAVEDESCRIPTION_SUCCESS, payload: data});
      console.log("Description saved:", data);
    } catch (error) {
      console.log("Error saving description:", error);
      dispatch({ type: types.SAVEDESCRIPTION_FAILURE, payload: error.message });
    }
  };

  //Update Description on project page
  export const EditDescription = (token, leadId, description) => async (dispatch) => {
    dispatch({ type: types.EDITDESCRIPTION_REQUEST });
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/updateDescription`,
        { leadId, description},  
        {
          headers: {
            Authorization: `${token}`, 
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.EDITDESCRIPTION_SUCCESS, payload: data });
      console.log("description updated:", data ); 
    } catch (error) {
      console.log(error);
      dispatch({ type: types.EDITDESCRIPTION_FAILURE });
    }
  };


  // Add Comment on the project Page
  export const SaveComment = (token, leadId, userId, commentText) => async (dispatch) => {
    dispatch({ type: types.SAVECOMMENT_REQUEST });
    console.log("Saving comment for leadId:", leadId);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/addComment`,
        { leadId, userId, commentText }, 
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
  
      const data = response.data;
      dispatch({ type: types.SAVECOMMENT_SUCCESS, payload: data });
      console.log("Comment saved:", data);
    } catch (error) {
      console.log("Error saving comment:", error);
      dispatch({ type: types.SAVECOMMENT_FAILURE, payload: error.message });
    }
  };


  //Edit Comment on the project Details
  export const EditComment = (token, leadId, commentId, newCommentText) => async (dispatch) => {
    dispatch({ type: types.EDITCOMMENT_REQUEST });
    console.log("commentId:", commentId)
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/editComment`,
        { leadId, commentId, newCommentText },  
        {
          headers: {
            Authorization: `${token}`, 
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.EDITCOMMENT_SUCCESS, payload: { ...data, commentId } });
      console.log({ ...data, commentId }); 
    } catch (error) {
      console.log(error);
      dispatch({ type: types.EDITCOMMENT_FAILURE });
    }
  };
  
  
  //delete comment of the project details
  export const deleteComment = (token, leadId, commentId) => async (dispatch) => {
    dispatch({ type: types.DELETECOMMENT_REQUEST });
  
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/deleteComment`,
        {
          headers: {
            Authorization: `${token}`,
          },
          data: {
            leadId, 
            commentId
          }
        }
      );
  
      const data = response.data;
      dispatch({ type: types.DELETECOMMENT_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.DELETECOMMENT_FAILURE });
    }
  };

  



  export{};