import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import Navbar from "../../Sidebar/Navbar";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

const ExpenseReport = ({ isSidebarExpanded }) => {
  const [data, setData] = useState([]);
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const navigate = useNavigate();

  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedExecutive, setSelectedExecutive] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/reports");
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

   // Category dropdowns

   const category = ["General", "Marketing", "Others", "Rent", "Salary"];

   const filteredCategory = category.filter((category) =>
     category.toLowerCase().includes(searchInput.toLowerCase())
   );
 
   const handleCategorySelect = (category) => {
     setSelectedCategory(category);
     setSearchInput("");
   };
 
   const executive = ["Name 1", "Name 2", "Name 3", "Name 4"];
 
   const filteredExecutive = executive.filter((executive) =>
     executive.toLowerCase().includes(searchInput.toLowerCase())
   );
 
   const handleExecutiveSelect = (executive) => {
     setSelectedExecutive(executive);
     setSearchInput("");
   };


  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Finance By Expense Report"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Flex p={3} mb={4}>
            <Heading
              color={"#151617"}
              as="h5"
              fontSize={"18px"}
              fontWeight={"bold"}
              fontFamily={"Public Sans, sans-serif"}
              mt={2}
            >
              {data.length} Expense(s) Found | Total Amount : 0 | Approved
              Amount : 0 | Pending Amount : 0
            </Heading>
            <Box position={"absolute"} right={10}>
              <Flex>
                <Flex
                  fontSize={"14px"}
                  border={"1px solid red"}
                  lineHeight={7}
                  borderRadius={8}
                  fontFamily={"Public Sans, sans-serif"}
                  color={"#D80000"}
                >
                  <Text
                    borderRight={"1px solid #D80000"}
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}
                    onClick={onOpen}
                  >
                    Filter
                  </Text>
                  <Text
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}
                    onClick={handleOnClick}
                  >
                    Go Back
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <TableContainer>
            <Table mt={4}>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th color={"white"}>#</Th>
                  <Th color={"white"}>Creator</Th>
                  <Th color={"white"}>ID</Th>
                  <Th color={"white"}>Date</Th>
                  <Th color={"white"}>Category</Th>
                  <Th color={"white"}>Amount</Th>
                  <Th color={"white"}>Details</Th>
                  <Th color={"white"}>Receipt</Th>
                  <Th color={"white"}>Mode</Th>
                  <Th color={"white"}>Approval Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.length === 0 ? (
                  <Tr>
                    <Td colSpan="10">
                      <Center
                        py={10}
                        fontSize={"26px"}
                        color={"#5D596C"}
                        fontWeight={500}
                      >
                        No Records Found.
                      </Center>
                    </Td>
                  </Tr>
                ) : (
                  data.map((expense, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{expense.creator}</Td>
                      <Td>{expense.id}</Td>
                      <Td>{expense.date}</Td>
                      <Td>{expense.category}</Td>
                      <Td>{expense.amount}</Td>
                      <Td>{expense.details}</Td>
                      <Td>{expense.receipt}</Td>
                      <Td>{expense.mode}</Td>
                      <Td>{expense.approvalStatus}</Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size={"lg"}
        >
          <DrawerOverlay />
          <DrawerContent w="100%" maxW="550px" overflowX="hidden">
            <DrawerCloseButton />
            <DrawerHeader>Filter</DrawerHeader>

            <DrawerBody>
              <Grid templateColumns="repeat(2, 1fr)" gap={4} w="100%">
                <FormControl mb={2} isRequired>
                  <Select name="range" required>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                  </Select>
                </FormControl>
                <FormControl mb={2} isRequired>
                  <Input type="text" placeholder="Search Term" required />
                </FormControl>
                <FormControl mb={2}>
                  <DatePicker
                    selected={selectedFromDate}
                    onChange={(date) => setSelectedFromDate(date)}
                    placeholderText="From Date"
                    customInput={<Input width="240px" />}
                    dateFormat="MM/dd/yyyy"
                  />
                </FormControl>
                <FormControl mb={2}>
                  <DatePicker
                    selected={selectedToDate}
                    onChange={(date) => setSelectedToDate(date)}
                    placeholderText="To Date"
                    customInput={<Input width="240px" />}
                    dateFormat="MM/dd/yyyy"
                  />
                </FormControl>
                <FormControl mb={2}>
                  <FormLabel
                    fontSize={"0.9375rem"}
                    lineHeight={"1.47"}
                    mb={-0.2}
                    color={"#151617"}
                  ></FormLabel>
                  <Menu>
                    <MenuButton
                      as={Button}
                      width="100%"
                      textAlign="left"
                      color={"#6f6b7d"}
                      background={"none"}
                      border={"1px solid #dbdade"}
                      _hover={{ bg: "none" }}
                    >
                      {selectedCategory ? selectedCategory : "Select Category"}
                    </MenuButton>
                    <MenuList maxHeight="200px" overflowY="auto">
                      <Box p={2}>
                        <Input
                          placeholder="Category..."
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </Box>
                      {filteredCategory.length > 0 ? (
                        filteredCategory.map((category, index) => (
                          <MenuItem
                            _hover={{
                              bg: "#D8000026",
                              textColor: "#D80000",
                            }}
                            key={index}
                            onClick={() => handleCategorySelect(category)}
                          >
                            {category}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No matches found</MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </FormControl>

                <FormControl mb={2}>
                  <FormLabel
                    fontSize={"0.9375rem"}
                    lineHeight={"1.47"}
                    mb={-0.2}
                    color={"#6f6b7d"}
                  ></FormLabel>
                  <Menu>
                    <MenuButton
                      as={Button}
                      // rightIcon={<IoChevronDownOutline />}
                      width="100%"
                      textAlign="left"
                      color={"#6f6b7d"}
                      background={"none"}
                      border={"1px solid #dbdade"}
                      _hover={{ bg: "none" }}
                    >
                      {selectedExecutive
                        ? selectedExecutive
                        : "Select Executive"}
                    </MenuButton>
                    <MenuList maxHeight="200px" overflowY="auto">
                      <Box p={2}>
                        <Input
                          placeholder="Executive..."
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </Box>
                      {filteredExecutive.length > 0 ? (
                        filteredExecutive.map((executive, index) => (
                          <MenuItem
                            _hover={{
                              bg: "#D8000026",
                              textColor: "#D80000",
                            }}
                            key={index}
                            onClick={() => handleExecutiveSelect(executive)}
                          >
                            {executive}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No matches found</MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </FormControl>
              </Grid>
            </DrawerBody>

            <DrawerFooter>
              <Button width={"100%"} height={7} mr={"7"} background={"#D80000"} color={"white"}>
                Filter
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Box>
    </Box>
  );
};

export default ExpenseReport;
