import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Textarea,
  Flex,
  Text,
  HStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Navbar from "../Sidebar/Navbar";
import { IoSettingsOutline } from "react-icons/io5";
import {
  TbAB2,
  TbApi,
  TbBriefcase,
  TbClick,
  TbDashboard,
  TbDeviceMobileMessage,
  TbFile3D,
  TbFileDollar,
  TbReceipt,
  TbReportSearch,
  TbRocket,
  TbTableOptions,
} from "react-icons/tb";
import { FaLink, FaRegBuilding, FaWhatsapp } from "react-icons/fa";
import { IoIosSend, IoMdCheckboxOutline } from "react-icons/io";
import { FaInfinity } from "react-icons/fa";
import { GrDocumentText } from "react-icons/gr";
import { PiClipboardTextBold } from "react-icons/pi";
import { HiOutlineTicket } from "react-icons/hi";

const Settings = ({ isSidebarExpanded }) => {
  const [value, setValue] = React.useState(null);
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";

  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <Box>
      <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Settings" />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          p={6}
          borderRadius={7}
          position={"relative"}
          top={100}
          height={"100%"}
        >
          <Grid templateColumns="repeat(3, 1fr)" columnGap={5}>
            <Box
              boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
              borderRadius={7}
              p={4}
              fontFamily="Public Sans"
            >
              <Heading
                fontSize={"18px"}
                color={"#5D596c"}
                textAlign={"left"}
                mb={8}
              >
                Primary Settings
              </Heading>
              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#28c76f !important"
                    backgroundColor={"#dff7e9 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <IoSettingsOutline size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    General Settings
                  </Heading>
                </HStack>
                <Box
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#4b4b4b !important"
                    backgroundColor={"#e4e4e4 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <FaInfinity size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Automation Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#00cfe8 !important"
                    backgroundColor={"#d9f8fc !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <FaLink size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Integration Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#ff9f43 !important"
                    backgroundColor={"#fff1e3 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <IoMdCheckboxOutline size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Active Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#7367f0 !important"
                    backgroundColor={"#eae8fd !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbRocket size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Deal Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#a8aaae !important"
                    backgroundColor={"#f2f2f3 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <GrDocumentText size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Fresh Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#ea5455 !important"
                    backgroundColor={"#fce5e6 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <PiClipboardTextBold size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Proposal & Products Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>
            </Box>

            <Box
              boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
              borderRadius={7}
              p={4}
              fontFamily="Public Sans"
            >
              <Heading
                fontSize={"18px"}
                color={"#5D596c"}
                mb={8}
                textAlign={"left"}
              >
                Additional Settings
              </Heading>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#28c76f !important"
                    backgroundColor={"#dff7e9 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbBriefcase size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Client Settings
                  </Heading>
                </HStack>
                <Box
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#4b4b4b !important"
                    backgroundColor={"#e4e4e4 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <HiOutlineTicket size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Support Ticket Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#00cfe8 !important"
                    backgroundColor={"#d9f8fc !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbFileDollar size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Invoice Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#ff9f43 !important"
                    backgroundColor={"#fff1e3 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbReceipt size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Proforma Invoice Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#7367f0 !important"
                    backgroundColor={"#eae8fd !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbApi size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    API Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#a8aaae !important"
                    backgroundColor={"#f2f2f3 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <FaWhatsapp size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    WhatsApp Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#ea5455 !important"
                    backgroundColor={"#fce5e6 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbDeviceMobileMessage size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    SMS Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>
            </Box>

            <Box
              boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
              borderRadius={7}
              p={4}
              fontFamily="Public Sans"
            >
              <Heading
                fontSize={"18px"}
                color={"#5D596c"}
                mb={8}
                textAlign={"left"}
              >
                Advanced Settings
              </Heading>
              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#28c76f !important"
                    backgroundColor={"#dff7e9 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbDashboard  size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Custom Dashboard Settings
                  </Heading>
                </HStack>
                <Box
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#4b4b4b !important"
                    backgroundColor={"#e4e4e4 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbReportSearch  size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Custom Report Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#00cfe8 !important"
                    backgroundColor={"#d9f8fc !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <IoIosSend  size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Email Campaign Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#ff9f43 !important"
                    backgroundColor={"#fff1e3 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbClick  size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Project & Task Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#7367f0 !important"
                    backgroundColor={"#eae8fd !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <FaRegBuilding  size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Company Profile Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#a8aaae !important"
                    backgroundColor={"#f2f2f3 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbAB2  size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    Vendor Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>

              <Flex mb={5} justify="space-between" align="center">
                <HStack>
                  <Box
                    height={"35px"}
                    width={"35px"}
                    color="#ea5455 !important"
                    backgroundColor={"#fce5e6 !important"}
                    pl={"7px"}
                    pt={"7px"}
                  >
                    <TbFile3D  size={20} />
                  </Box>
                  <Heading
                    fontSize={"15px"}
                    color={"#5d596c"}
                  >
                    P.O Settings
                  </Heading>
                </HStack>
                <Box
                  position={"relative"}
                  height={"35px"}
                  width={"35px"}
                  color="white !important"
                  borderRadius={7}
                  backgroundColor={"#D80000 !important"}
                  pl={"7px"}
                  pt={"7px"}
                >
                  <TbTableOptions size={20} />
                </Box>
              </Flex>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
