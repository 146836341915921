import {
  Box,
  Button,
  Center,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import Navbar from "../../Sidebar/Navbar";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { FiFileText, FiPrinter } from "react-icons/fi";
import { ChevronDownIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import { IoChevronDownOutline } from "react-icons/io5";

const SalesReport = ({ isSidebarExpanded }) => {
  const [data, setData] = useState([]);
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [selectedExecutive, setSelectedExecutive] = useState("");

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/reports");
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  // Executive dropdowns

  const executive = ["Name 1", "Name 2", "Name 3", "Name 4"];

  const filteredExecutive = executive.filter((executive) =>
    executive.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleExecutiveSelect = (executive) => {
    setSelectedExecutive(executive);
    setSearchInput("");
  };

  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Sales Report"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Flex p={3} mb={4}>
            <Heading
              color={"#151617"}
              as="h5"
              fontSize={"18px"}
              fontWeight={"bold"}
              fontFamily={"Public Sans, sans-serif"}
              mt={2}
            >
              0 Record(s) Found
            </Heading>
            <Box position={"absolute"} right={10}>
              <Flex>
                <Flex
                  fontSize={"14px"}
                  border={"1px solid red"}
                  lineHeight={7}
                  borderRadius={8}
                  fontFamily={"Public Sans, sans-serif"}
                  color={"#D80000"}
                >
                  <Menu>
                    <MenuButton
                      rightIcon={<ChevronDownIcon />}
                      borderRight={"1px solid #D80000"}
                      pl={4}
                      pr={4}
                      cursor={"pointer"}
                      _hover={{ bg: "#D8000026" }}
                    >
                      Export
                    </MenuButton>
                    <MenuList minW="140px">
                      <MenuItem color={"black"} icon={<FiPrinter />}>
                        Print
                      </MenuItem>
                      <MenuItem color={"black"} icon={<FiFileText />}>
                        CSV
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Text
                    borderRight={"1px solid #D80000"}
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}
                    onClick={onOpen}
                  >
                    Filter
                  </Text>
                  <Text
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}
                    onClick={handleOnClick}
                  >
                    Go Back
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <TableContainer>
            <Table mt={4}>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th color={"white"}>#</Th>
                  <Th color={"white"}>Executive</Th>
                  <Th color={"white"}>Booking Date</Th>
                  <Th color={"white"}>Travel Date</Th>
                  <Th color={"white"}>Product Type</Th>
                  <Th color={"white"}>Booking ID</Th>
                  <Th color={"white"}>Lead Pax</Th>
                  <Th color={"white"}>No of PAX </Th>
                  <Th color={"white"}>Destination</Th>
                  <Th color={"white"}>Booking value excluding GST</Th>
                  <Th color={"white"}>Flight cost</Th>
                  <Th color={"white"}>Net land package</Th>
                  <Th color={"white"}>visa cost</Th>
                  <Th color={"white"}>Insurance cost</Th>
                  <Th color={"white"}>Flight services cost</Th>
                  <Th color={"white"}>Flight GST @18%</Th>
                  <Th color={"white"}>land service fee</Th>
                  <Th color={"white"}>IGST 18%, 5%</Th>
                  <Th color={"white"}>CGST 2.5%</Th>
                  <Th color={"white"}>SGST 2.5%</Th>
                  <Th color={"white"}>CGST 9%</Th>
                  <Th color={"white"}>SGST 9%</Th>
                  <Th color={"white"}>TCS 5%</Th>
                  <Th color={"white"}>Booking value including GST</Th>
                  <Th color={"white"}>Amount recived </Th>
                  <Th color={"white"}>Balance payable</Th>
                  <Th color={"white"}>GOP%</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.length === 0 ? (
                  <Tr>
                    <Td colSpan={16}>
                      <Center py={10}>
                        <Text
                          fontSize={"26px"}
                          color={"#5D596C"}
                          fontWeight={500}
                        >
                          No Records Found.
                        </Text>
                      </Center>
                    </Td>
                  </Tr>
                ) : (
                  data.map((sales, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{sales.executive}</Td>
                      <Td>{sales.bookingDate}</Td>
                      <Td>{sales.travelDate}</Td>
                      <Td>{sales.productType}</Td>
                      <Td>{sales.bookingId}</Td>
                      <Td>{sales.leadPax}</Td>
                      <Td>{sales.noOfPax}</Td>
                      <Td>{sales.destination}</Td>
                      <Td>{sales.bookingValueExclGst}</Td>
                      <Td>{sales.flightCost}</Td>
                      <Td>{sales.netLandPackage}</Td>
                      <Td>{sales.visaCost}</Td>
                      <Td>{sales.insuranceCost}</Td>
                      <Td>{sales.flightServicesCost}</Td>
                      <Td>{sales.flightGst_18pc}</Td>
                      <Td>{sales.landServiceFee}</Td>
                      <Td>{sales.iGST_18pc_5pc}</Td>
                      <Td>{sales.cGST_3pc}</Td>
                      <Td>{sales.sGST_3pc}</Td>
                      <Td>{sales.cGST_9pc}</Td>
                      <Td>{sales.sGST_9pc}</Td>
                      <Td>{sales.tCS_5pc}</Td>
                      <Td>{sales.bookingValueInclGst}</Td>
                      <Td>{sales.amountReceived}</Td>
                      <Td>{sales.balancePayable}</Td>
                      <Td>{sales.gOP_pc}</Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size={"md"}
        >
          <DrawerOverlay />
          <DrawerContent w="100%" maxW="550px" overflowX="hidden">
            <DrawerCloseButton />
            <DrawerHeader>Filters</DrawerHeader>

            <DrawerBody>
              <Grid templateColumns="repeat(2, 1fr)" gap={4} w="100%">
                <FormControl mb={2} isRequired>
                  <Select name="range" required>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                  </Select>
                </FormControl>

                <FormControl mb={2}>
                  <FormLabel
                    fontSize={"0.9375rem"}
                    lineHeight={"1.47"}
                    mb={-0.2}
                    color={"#6f6b7d"}
                  ></FormLabel>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<IoChevronDownOutline />}
                      width="100%"
                      textAlign="left"
                      color={"#6f6b7d"}
                      background={"none"}
                      border={"1px solid #dbdade"}
                      _hover={{ bg: "none" }}
                    >
                      {selectedExecutive
                        ? selectedExecutive
                        : "Select Executive"}
                    </MenuButton>
                    <MenuList maxHeight="200px" overflowY="auto">
                      <Box p={2}>
                        <Input
                          placeholder="Executive..."
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </Box>
                      {filteredExecutive.length > 0 ? (
                        filteredExecutive.map((executive, index) => (
                          <MenuItem
                            _hover={{
                              bg: "#D8000026",
                              textColor: "#D80000",
                            }}
                            key={index}
                            onClick={() => handleExecutiveSelect(executive)}
                          >
                            {executive}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No matches found</MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </FormControl>

                <FormControl mb={2}>
                  <DatePicker
                    selected={selectedFromDate}
                    onChange={(date) => setSelectedFromDate(date)}
                    placeholderText="From Date"
                    customInput={<Input width="240px" />}
                    dateFormat="MM/dd/yyyy"
                  />
                </FormControl>
                <FormControl mb={2}>
                  <DatePicker
                    selected={selectedToDate}
                    onChange={(date) => setSelectedToDate(date)}
                    placeholderText="To Date"
                    customInput={<Input width="240px" />}
                    dateFormat="MM/dd/yyyy"
                  />
                </FormControl>
              </Grid>
            </DrawerBody>

            <DrawerFooter>
              <Button
                width={"100%"}
                height={7}
                mr={"7"}
                background={"#D80000"}
                color={"white"}
              >
                Filter
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Box>
    </Box>
  );
};

export default SalesReport;
