import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  useToast,
  IconButton,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  HStack,
  FormLabel,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import React, { useEffect, useState, useMemo } from "react";
import Navbar from "./Sidebar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteComment,
  EditComment,
  EditDescription,
  GetProject,
  SaveComment,
  SaveDescription,
} from "../Redux/AppReducer/Action";
import {
  IoChevronDownOutline,
  IoTimerOutline,
  IoCloseSharp,
} from "react-icons/io5";
import { FaCheck, FaLink } from "react-icons/fa6";
import { GrAttachment } from "react-icons/gr";
import { AiOutlinePartition } from "react-icons/ai";

import Mention from "quill-mention";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";

Quill.register("modules/mention", Mention);

const Projects = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const getAllProject = useSelector((state) => state.app.GETPROJECTs);

  const [currentProject, setCurrentProject] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isEditing, setIsEditing] = useState(null);

  const [searchInput, setSearchInput] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [options, setOptions] = useState(["FINANCE READY", "DONE"]);
  const [selectedOption, setSelectedOption] = useState("TO DO");
  const [selectedAssignees, setSelectedAssignees] = useState({});

  const [projectNames, setProjectNames] = useState({});
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editingCommentText, setEditingCommentText] = useState("");
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");

  const handleSaveProjectName = () => {
    setIsEditingName(false);
  };

  const handleSaveProjectDescription = () => {
    if (description.trim() && currentProject?.leadId) {
    
        dispatch(EditDescription(token, currentProject.leadId, description));
        dispatch(GetProject(token));
        toast({
          title: "Project description saved.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
    
      setCurrentProject((prev) => ({
        ...prev,
        description,
      }));
      setIsEditingDescription(false);
    } else {
      toast({
        title: "Description cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const colorMapping = {
    "TO DO": "gray",
    "FINANCE READY": "blue",
    DONE: "green",
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setOptions((prevOptions) =>
      prevOptions.filter((item) => item !== option).concat(selectedOption)
    );
  };

  const toast = useToast();

  useEffect(() => {
    dispatch(GetProject(token));
  }, [dispatch, token]);

  const handleProjectClick = (project) => {
    console.log(project)
    setCurrentProject(project);
    setComments(project.comments || []);
    setIsTableVisible(false);
    setProjectName(projectNames[project.leadId] || project.projectName || "");
    setDescription(project.description || "");
  };

  const handleCloseProjectDetails = () => {
    setCurrentProject(null);
    setIsTableVisible(true);
  };

  const userId = useSelector((state) => state.auth.user.id);

  const sanitizeComment = (text) => {
    const doc = new DOMParser().parseFromString(text, "text/html");
    return doc.body.textContent || "";
  };

  const handleAddOrEditComment = () => {
    if (newComment.trim()) {
      const sanitizedComment = sanitizeComment(newComment);
      const updatedComments =
        isEditing !== null
          ? comments.map((comment, index) =>
              index === isEditing
                ? {
                    ...comment,
                    text: sanitizedComment,
                    date: new Date().toLocaleString(),
                  }
                : comment
            )
          : [
              { text: sanitizedComment, date: new Date().toLocaleString() },
              ...comments,
            ];
      dispatch(
        SaveComment(token, currentProject.leadId, userId, sanitizedComment)
      );

      setComments(updatedComments);
      setNewComment("");
      setIsEditing(null);
    } else {
      toast({
        title: "Comment cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditComment = (index) => {
    setIsEditing(index);
    setEditingCommentText(comments[index].commentText);
  };

  const handleDeleteComment = (index) => {
    const commentId = currentProject.comments[index]?._id;
    console.log("Comment ID being deleted: ", commentId);
    const updatedComments = comments.filter((_, i) => i !== index);
    dispatch(deleteComment(token, currentProject.leadId, commentId));
    setComments(updatedComments);
    toast({
      title: "Comment deleted.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };
  

  const users = useMemo(
    () => [
      { id: 1, value: "Sridhar" },
      { id: 2, value: "Prakruthi" },
      { id: 3, value: "Ansu Kumari" },
      { id: 4, value: "Nithin Bangera" },
    ],
    []
  );

  const mentionModule = useMemo(
    () => ({
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["@"],
        source: (searchTerm, renderList) => {
          const matches = users.filter((user) =>
            user.value.toLowerCase().includes(searchTerm.toLowerCase())
          );
          renderList(matches, searchTerm);
        },
      },
    }),
    [users]
  );

  const assignee = ["Unassigned", "Name 1", "Name 2", "Name 3", "Name 4"];

  const filteredAssignee = assignee.filter((assignee) =>
    assignee.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleAssigneeSelect = (projectId, assignee) => {
    setSelectedAssignees((prev) => ({
      ...prev,
      [projectId]: assignee,
    }));
    setSearchInput("");
  };

  const handleSaveEditedComment = () => {
    if (editingCommentText.trim()) {
      const sanitizedComment = sanitizeComment(editingCommentText);
      const commentId = currentProject.comments[isEditing]?._id;
      console.log("Comment ID being edited: ", commentId);
      dispatch(
        EditComment(token, currentProject.leadId, commentId, sanitizedComment)
      );
      const updatedComments = comments.map((comment, index) =>
        index === isEditing
          ? {   
              ...comment,
              commentText: sanitizedComment,  
              date: new Date().toLocaleString(),
            }
          : comment
      );
      setComments(updatedComments);
      setIsEditing(null);
      setEditingCommentText("");
    } else {
      toast({
        title: "Comment cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  

  return (
    <Box>
      <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Projects" />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        {isTableVisible && (
          <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            position={"relative"}
            top={100}
          >
            <Flex p={3} mb={4}>
              <Heading
                color={"#151617"}
                as="h5"
                fontSize={"18px"}
                fontWeight={"bold"}
                fontFamily={"Public Sans, sans-serif"}
              >
                {getAllProject?.leads?.length} record
                {getAllProject?.leads?.length !== 1 ? "s" : ""} found
              </Heading>
            </Flex>
            <TableContainer>
              <Table mt={2}>
                <Thead>
                  <Tr backgroundColor={"#4b4b4b"}>
                    <Th>
                      <Checkbox />
                    </Th>
                    <Th color={"white"}>#</Th>
                    <Th color={"white"}>ID</Th>
                    <Th color={"white"}>Project Name</Th>
                    <Th color={"white"}>Assignee</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {getAllProject?.projects?.map((Leads, index) => (
                    <Tr key={index}>
                      <Td>
                        <Checkbox />
                      </Td>
                      <Td>{index + 1}</Td>
                      <Td
                        onClick={() => handleProjectClick(Leads)}
                        cursor={"pointer"}
                        color={"blue.500"}
                      >
                        {Leads.leadId}
                      </Td>
                      <Td>{Leads.projectName}</Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            width="250px"
                            as={Button}
                            textAlign="left"
                            color={"#6f6b7d"}
                            background={"none"}
                            _hover={{ bg: "none", border: "none" }}
                            border="none"
                            focusBorderColor="none"
                          >
                            {selectedAssignees[Leads.leadId] || "Unassigned"}
                          </MenuButton>
                          <MenuList maxHeight="200px" overflowY="auto">
                            <Box p={2}>
                              <Input
                                placeholder="Select..."
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                              />
                            </Box>
                            {filteredAssignee.length > 0 ? (
                              filteredAssignee.map((assignee, index) => (
                                <MenuItem
                                  _hover={{
                                    bg: "#D8000026",
                                    textColor: "#D80000",
                                  }}
                                  key={index}
                                  onClick={() =>
                                    handleAssigneeSelect(Leads.leadId, assignee)
                                  }
                                >
                                  {assignee}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No matches found</MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {currentProject && (
          <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            position={"relative"}
            top={100}
            p={6}
          >
            <Flex
              justifyContent="space-between"
              overflowY="auto"
              alignItems="center"
              mb={4}
            >
              <Box
                as="h4"
                fontSize={20}
                fontWeight="bold"
                color={"grey"}
                textAlign={"left"}
                overflow={"auto"}
              >
                Project Details
              </Box>
              <IconButton
                icon={<IoCloseSharp size={20} />}
                onClick={handleCloseProjectDetails}
                aria-label="Close Project Details"
              />
            </Flex>
            <Flex>
              <Box w="70%" h="400px" overflowY="auto" p={6}>
                <VStack>
                  <Box w={"full"} mb={2}>
                    {isEditingName ? (
                      <HStack spacing={4}>
                        <Input
                          value={projectName}
                          onChange={(e) => setProjectName(e.target.value)}
                          autoFocus
                        />
                        <IconButton
                          icon={<FaCheck />}
                          onClick={handleSaveProjectName}
                          aria-label="Save"
                        />
                        <IconButton
                          icon={<IoCloseSharp size={20} />}
                          onClick={() => setIsEditingName(false)}
                          aria-label="Cancel"
                        />
                      </HStack>
                    ) : (
                      <Heading size="md" onClick={() => setIsEditingName(true)}>
                        {projectName || "Unnamed Project"}
                      </Heading>
                    )}
                  </Box>
                  <Box w={"full"}>
                    <Flex gap={4}>
                      <Button h={8}>
                        <GrAttachment />
                        Attach
                      </Button>
                      <Button h={8}>
                        <AiOutlinePartition />
                        Add a child issue
                      </Button>
                      <Button h={8}>
                        <FaLink />
                        Link Issue
                      </Button>
                      <Button h={8}>Create</Button>
                      <Button h={8}>
                        <IoTimerOutline />
                        Start work
                      </Button>
                    </Flex>
                  </Box>
                  <Box w="full">
                    <FormLabel as={"h3"} fontWeight={"bold"}>
                      Description :
                    </FormLabel>
                    {isEditingDescription ? (
                      <>
                        <Input
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          autoFocus
                        />
                        <HStack mt={2} spacing={4}>
                          <Button onClick={handleSaveProjectDescription}>
                            Save
                          </Button>
                          <Button
                            onClick={() => setIsEditingDescription(false)}
                          >
                            Cancel
                          </Button>
                        </HStack>
                      </>
                    ) : (
                      <Box
                        onClick={() => setIsEditingDescription(true)}
                        _hover={{ cursor: "pointer", bg: "#f5f5f5" }}
                        p={3}
                        borderRadius={5}
                        borderWidth={1}
                      >
                        <Text>
                          {description || "Add project description..."}
                        </Text>
                      </Box>
                    )}
                  </Box>
                  <Box w="full">
                    <FormLabel as="h3" fontWeight="bold">
                      Comments :
                    </FormLabel>
                    <Box>
                      <ReactQuill
                        theme="snow"
                        value={newComment}
                        onChange={setNewComment}
                        modules={mentionModule}
                        placeholder="Add a comment with @mention..."
                        style={{ minHeight: "120px" }}
                      />
                      <HStack spacing={4} mt={2}>
                        <Button onClick={handleAddOrEditComment}>Save</Button>
                        <Button onClick={() => setNewComment("")}>
                          Cancel
                        </Button>
                      </HStack>
                    </Box>
                    {comments.length > 0 ? (
                      comments.map((comment, index) => {
                        // console.log(comment,comments)
                        return      ( 
                          <Box
                            key={index}
                            my={4}
                            p={4}
                            borderWidth={1}
                            borderRadius="md"
                          >
                            {isEditing === index ? (
                              <>
                                <ReactQuill
                                  theme="snow"
                                  value={editingCommentText}
                                  onChange={setEditingCommentText}
                                />
                                <HStack mt={2} spacing={4}>
                                  <Button onClick={handleSaveEditedComment}>
                                    Save
                                  </Button>
                                  <Button onClick={() => setIsEditing(null)}>
                                    Cancel
                                  </Button>
                                </HStack>
                              </>
                            ) : (
                              <>
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: comment.commentText,
                                  }}
                                  mb={2}
                                ></Box>
                                <HStack justifyContent="space-between">
                                  <Text fontSize="sm" color="gray.500">
                                    {comment.createdAt?.split("T")[0]}
                                  </Text>
                                  <HStack>
                                    <Button
                                      variant="link"
                                      onClick={() => handleEditComment(index)}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      variant="link"
                                      ml={2}
                                      onClick={() => handleDeleteComment(index)}
                                    >
                                      Delete
                                    </Button>
                                  </HStack>
                                </HStack>
                              </>
                            )}
                          </Box>
                        )
                      }
                   )
                    ) : (
                      <Text pl={2}>No comments yet.</Text>
                    )}
                  </Box>
                </VStack>
              </Box>
              <Box w="30%" h="400px" overflowY="auto" p={6} borderLeftWidth={1}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDownOutline />}
                    colorScheme={colorMapping[selectedOption] || "gray"}
                    h={8}
                    fontSize={14}
                  >
                    {selectedOption}
                  </MenuButton>
                  <MenuList>
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
                <Box mt={4} border={"1px solid #DFE1E6"}>
                  <Accordion allowToggle>
                    <AccordionItem border="none">
                      <h2>
                        <AccordionButton
                          p={4}
                          borderBottom={"1px solid #DFE1E6"}
                        >
                          <Heading
                            as="span"
                            flex="1"
                            textAlign="left"
                            fontSize={18}
                          >
                            Details
                          </Heading>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel p={4}>
                        {currentProject && (
                          <Box>
                            <Text>
                              <FormLabel>Assignee:</FormLabel>
                              <Menu>
                                <MenuButton
                                  width="250px"
                                  as={Button}
                                  textAlign="left"
                                  color={"#6f6b7d"}
                                  background={"none"}
                                  _hover={{ bg: "none", border: "none" }}
                                  border="none"
                                  focusBorderColor="none"
                                >
                                  {selectedAssignees[currentProject.leadId] ||
                                    "Unassigned"}
                                </MenuButton>
                                <MenuList maxHeight="200px" overflowY="auto">
                                  <Box p={2}>
                                    <Input
                                      placeholder="Select..."
                                      value={searchInput}
                                      onChange={(e) =>
                                        setSearchInput(e.target.value)
                                      }
                                    />
                                  </Box>
                                  {filteredAssignee.length > 0 ? (
                                    filteredAssignee.map((assignee, index) => (
                                      <MenuItem
                                        _hover={{
                                          bg: "#D8000026",
                                          textColor: "#D80000",
                                        }}
                                        key={index}
                                        onClick={() =>
                                          handleAssigneeSelect(
                                            currentProject.leadId,
                                            assignee
                                          )
                                        }
                                      >
                                        {assignee}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem disabled>
                                      No matches found
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </Menu>
                            </Text>
                            <Flex>
                              <FormLabel mt={3}>Reporter:</FormLabel>
                              <Text>{currentProject.reporter}</Text>
                            </Flex>
                            <Flex>
                              <FormLabel mt={3}>Created Date:</FormLabel>
                              <Text>{currentProject.createdDate}</Text>
                            </Flex>
                            <Flex>
                              <FormLabel mt={3}>Created Time:</FormLabel>
                              <Text>{currentProject.createdTime}</Text>
                            </Flex>
                          </Box>
                        )}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
                <Box mt={4} border={"1px solid #DFE1E6"}>
                  <Accordion allowToggle>
                    <AccordionItem border="none">
                      <h2>
                        <AccordionButton
                          p={4}
                          borderBottom={"1px solid #DFE1E6"}
                        >
                          <Heading
                            as="span"
                            flex="1"
                            textAlign="left"
                            fontSize={18}
                          >
                            Client Details
                          </Heading>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel p={4}>
                        {currentProject && (
                          <Box>
                            <FormLabel mt={3}>Company Name:</FormLabel>
                            <Text>{currentProject.companyName}</Text>
                            <FormLabel mt={3}>Company Address:</FormLabel>
                            <Text>{currentProject.companyAddress}</Text>
                            <FormLabel mt={3}>Phone Number:</FormLabel>
                            <Text>{currentProject.phoneNumber}</Text>
                            <FormLabel mt={3}>Email Address:</FormLabel>
                            <Text>{currentProject.email}</Text>
                            <FormLabel mt={3}>Website:</FormLabel>
                            <Text>{currentProject.website}</Text>
                            <FormLabel mt={3}>City:</FormLabel>
                            <Text>{currentProject.city}</Text>
                            <FormLabel mt={3}>State</FormLabel>
                            <Text>{currentProject.state}</Text>
                            <FormLabel mt={3}>Country:</FormLabel>
                            <Text>{currentProject.country}</Text>
                            <FormLabel mt={3}>Zipcode:</FormLabel>
                            <Text>{currentProject.zipcode}</Text>
                            <FormLabel mt={3}>Nature of Business:</FormLabel>
                            <Text>{currentProject.natureOfBusiness}</Text>
                            <FormLabel mt={3}>Number of Employees:</FormLabel>
                            <Text>{currentProject.NoOfEmployees}</Text>
                            <FormLabel mt={3}>
                              Company Registration Type:
                            </FormLabel>
                            <Text>{currentProject.registrationType}</Text>
                            <FormLabel mt={3}>Standard:</FormLabel>
                            <Text>{currentProject.standard}</Text>
                            <FormLabel mt={3}>Service Type:</FormLabel>
                            <Text>{currentProject.serviceType}</Text>
                          </Box>
                        )}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              </Box>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Projects;
