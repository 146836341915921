import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Navbar from "../Sidebar/Navbar";
import { TbAddressBook, TbBrandGmail, TbSettings } from "react-icons/tb";
import { RiKey2Line } from "react-icons/ri";
import { FiUser } from "react-icons/fi";
import Logo_Preview from "../Images/Certvalue-Logo.webp";
import Select from "react-select";
import countryList from "react-select-country-list";
import { MdAlarm } from "react-icons/md";
import TimezoneSelect from "react-timezone-select";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';



const CompanyProfile = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const options = countryList().getData();
  const [value, setValue] = React.useState(null);

  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [phone, setPhone] = useState('');

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#D80000" : "black",
      backgroundColor: state.isFocused ? "#D8000026" : "white",
    }),
  };

  const handleChange = (value) => {
    setValue(value);
  };

  const timezoneStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: "8px",
      margin: "5px",
      borderRadius: "5px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#D80000" : "white",
      ":hover": {
        backgroundColor: "#D8000026",
        color: "#D80000",
      },
    }),
  };

  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Company Profile (Parent ID:12345)"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          // p={3}
          borderRadius={7}
          position={"relative"}
          top={100}
          padding="25px 15px 15px 15px"
          textAlign={"left"}
        >
          <Heading fontSize={"18px"} color={"#151617"}>
            Current Plan
          </Heading>
          <Text
            fontSize={"16px"}
            color={"#5D596C"}
            pt={"6px"}
            pb={"6px"}
            fontWeight={500}
          >
            Your Current Plan is Hexagon
          </Text>
          <Text
            fontSize={"16px"}
            color={"#5D596C"}
            pt={"6px"}
            pb={"6px"}
            fontWeight={500}
          >
            No. Of Users 70
          </Text>
          <Text
            fontSize={"16px"}
            color={"#5D596C"}
            pt={"6px"}
            pb={"6px"}
            fontWeight={500}
          >
            Active until Nov 08, 2024
          </Text>
        </Box>
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius={7}
          height={"auto"}
          position={"relative"}
          top={"130px"}
        >
          <Tabs>
            <TabList>
              <Grid templateColumns="repeat(6, 1fr)">
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  lineHeight={1.375}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <FiUser size={25} />
                    <Text fontSize={"0.9375rem"}>Primary Information</Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <TbAddressBook size={25} />
                    <Text fontSize={"0.9375rem"}>Address</Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <MdAlarm size={25} />
                    <Text fontSize={"0.9375rem"}>Timezone</Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <TbSettings size={25} />
                    <Text fontSize={"0.9375rem"}>Additional Settings</Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <TbBrandGmail size={25} />
                    <Text fontSize={"0.9375rem"}>Gmail Mailbox Settings</Text>
                  </Flex>
                </Tab>
                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <TbBrandGmail size={25} />
                    <Text fontSize={"0.9375rem"}>
                      Outlook/office360 Settings
                    </Text>
                  </Flex>
                </Tab>

                <Tab
                  color={"#5d595c"}
                  fontWeight={500}
                  _hover={{ color: "#D80000" }}
                  _selected={{
                    color: "#D80000",
                    borderBottomColor: "#D80000",
                  }}
                >
                  <Flex align="center">
                    <RiKey2Line size={25} />
                    <Text fontSize={"0.9375rem"}>API Keys</Text>
                  </Flex>
                </Tab>
              </Grid>
            </TabList>

            <TabPanels p={4}>
              {/* Primary Information TabPanel */}
              <TabPanel>
                <Text
                  fontSize={"15px"}
                  color={"#FF9F43"}
                  fontWeight={500}
                  background={"#FFF0F1"}
                  p={"13px"}
                  mb={8}
                  textAlign={"left"}
                  borderRadius={7}
                >
                  The below details will be used in all your headers like
                  invoices, proposals, etc.,
                </Text>
                <Grid templateColumns="repeat(4, 1fr)" gap={4} pl={8} pr={8}>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Company Name : *
                    </FormLabel>
                    <Input
                      type="text"
                      name="Company Name"
                      value={"CERTVALUE"}
                      required
                    />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Website:
                    </FormLabel>
                    <Input
                      type="text"
                      name="Website"
                      value={"www.certvalue.com"}
                      required
                    />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Currency : *
                    </FormLabel>
                    <Input type="text" name="Currency" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Vat/Tax No/GST :
                    </FormLabel>
                    <Input type="text" name="GST" value={"212CS334HJDGS"} />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Logo Preview :
                    </FormLabel>
                    <Image src={Logo_Preview} height={"20px"} />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Upload Logo :
                    </FormLabel>
                    <Input type="file" accept=".jpg, .webp, .jpeg" name="Logo" pt={1} />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Address Line 1 :
                    </FormLabel>
                    <Input
                      type="text"
                      name="Address"
                      value={
                        "4025-4026, 1st floor, 6th cross road, Jayanagar 7th, Krishna Rajendra Rd, opp. UCO Bank, "
                      }
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Address Line 2 :
                    </FormLabel>
                    <Input type="text" name="Address" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      City :
                    </FormLabel>
                    <Input type="text" name="City" value={"Bangalore"} />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      State :
                    </FormLabel>
                    <Input type="text" name="State" value={"Karnataka"} />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel
                      fontSize={"0.9375rem"}
                      lineHeight={"1.47"}
                      mb={-0.1}
                      color={"#6f6b7d"}
                    >
                      Country:
                    </FormLabel>
                    <Select
                      options={options}
                      styles={customStyles}
                      maxMenuHeight={"200px"}
                      value={value}
                      onChange={handleChange}
                      placeholder="Select your country"
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Zipcode :
                    </FormLabel>
                    <Input type="number" name="Zipcode" value={"560082"} />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Company Phone :
                    </FormLabel>
                    <Input type="phone" name="Phone" value={"6361529370"} />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Company Email :
                    </FormLabel>
                    <Input
                      type="email"
                      name="Company Email"
                      value={"contact@certvalue.com"}
                    />
                  </FormControl>
                  <Grid templateColumns="repeat(1, 2fr)">
                    <Flex flexDirection={"row"}>
                      <Checkbox alignItems={"top"} mt={1} mr={2} />
                      <Text>
                        Restrict User Access to Primary Company Profile
                      </Text>
                    </Flex>
                  </Grid>
                </Grid>
                <Flex justifyContent={"flex-start"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={"50px"}
                  >
                    Update Settings
                  </Button>
                </Flex>
              </TabPanel>

              {/* Address TabPanel */}
              <TabPanel>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.1} color={"#6f6b7d"}>
                      Billing Address:
                    </FormLabel>
                    <Textarea
                      type="text"
                      name="Billing Address"
                      value={
                        "4025-4026, 1st floor, 6th cross road, Krishna Rajendra Rd, opp. UCO Bank, 7th Block, Bengaluru, Karnataka 560082"
                      }
                      minHeight={"65px"}
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.1} color={"#6f6b7d"}>
                      Shipping Address:
                    </FormLabel>
                    <Textarea
                      type="text"
                      name="Shipping Address"
                      minHeight={"65px"}
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.1} color={"#6f6b7d"}>
                      Godown Address:
                    </FormLabel>
                    <Textarea
                      type="text"
                      name="Godown Address:"
                      minHeight={"65px"}
                    />
                  </FormControl>
                </Grid>
                <Flex justifyContent={"flex-start"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={"50px"}
                  >
                    Update Settings
                  </Button>
                </Flex>
              </TabPanel>

              {/* Timezone TabPanels */}
              <TabPanel>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.1} color={"#6f6b7d"}>
                      Timezone Settings:
                    </FormLabel>
                    <TimezoneSelect
                      value={selectedTimezone}
                      onChange={setSelectedTimezone}
                      styles={timezoneStyles}
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <Text type="text" name="Time" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.1} color={"#6f6b7d"}>
                      Country Code for Contact Number:
                    </FormLabel>
                    <PhoneInput
                      country={"us"}
                      value={phone}
                      onChange={setPhone}
                    />
                  </FormControl>

                  {/* <FormControl mb={2}>
                    <FormLabel mb={-0.1} color={"#6f6b7d"}>
                      Enter default country code:
                    </FormLabel>
                    <Input type="text" name="Country Code" />
                  </FormControl> */}
                </Grid>
                <Flex justifyContent={"flex-start"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={"50px"}
                  >
                    Update Settings
                  </Button>
                </Flex>
              </TabPanel>

              {/* Additional settings TabPanels */}
              <TabPanel>
                <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={"40px"}>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Restrict IPs for your Users :
                    </FormLabel>
                    <Text textAlign={"left"}>
                      If restricted, then your employees can be able to access
                      the CRM only from the allowed IPs but admin can access the
                      CRM from any IP.
                    </Text>
                    <Input type="text" name="Restrict IPs" />
                  </FormControl>
                  <Box />
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <Flex>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      IP Restriction Type
                    </FormLabel>
                    <Input type="text" name="IP Restriction Type" />
                  </Flex>
                  <Box />
                </Grid>

                <Flex justifyContent={"flex-start"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={"50px"}
                  >
                    Update Settings
                  </Button>
                </Flex>
              </TabPanel>

              {/* Additional settings TabPanels */}
              <TabPanel>
                <Heading
                  fontSize={"22px"}
                  fontWeight={500}
                  color={"#5D596C"}
                  textAlign={"left"}
                  mb={"20px"}
                >
                  Google App Settings
                </Heading>
                <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={"40px"}>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Client Id:
                    </FormLabel>
                    <Input
                      type="text"
                      name="Client Id"
                      value={"1233456789-4m56uvjat.apps.googleusercontent.com"}
                    />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Client Secret:
                    </FormLabel>
                    <Input
                      type="text"
                      name="Client Secret"
                      value={"ABCD-1234"}
                    />
                  </FormControl>
                </Grid>

                <Flex justifyContent={"flex-start"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={"30px"}
                  >
                    Update Settings
                  </Button>
                </Flex>
              </TabPanel>

              {/* Outlook/office360 Settings TabPanels */}
              <TabPanel>
                <Heading
                  fontSize={"22px"}
                  fontWeight={500}
                  color={"#5D596C"}
                  textAlign={"left"}
                  mb={"20px"}
                >
                  Microsoft Azure App Settings
                </Heading>
                <Text textAlign={"left"}>
                  To setup azure app, please register an application in azure go
                  to <Link color={"#7367F0"}> https://portal.azure.com.</Link>{" "}
                  When registering the app add the redirect uri
                  https://crm.certvalue.in/smtpsetting/outlookauthstatus
                </Text>
                <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={"40px"}>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Client Id:
                    </FormLabel>
                    <Input type="text" name="Client Id" />
                  </FormControl>

                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Client Secret:
                    </FormLabel>
                    <Input type="text" name="Client Secret" />
                  </FormControl>
                  <FormControl mb={2}>
                    <FormLabel mb={-0.2} color={"#6f6b7d"}>
                      Tanent Id:
                    </FormLabel>
                    <Input type="text" name="Tanent Id" />
                  </FormControl>
                </Grid>

                <Flex justifyContent={"flex-start"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={"30px"}
                  >
                    Update Settings
                  </Button>
                </Flex>
              </TabPanel>

              {/* API Key TabPanels */}
              <TabPanel>
                <Heading
                  fontSize={"22px"}
                  fontWeight={500}
                  color={"#5D596C"}
                  textAlign={"left"}
                  mb={"20px"}
                >
                  API Key
                </Heading>
                <Text textAlign={"left"}>5432rjhsjhffdj34h43kjnn55hj25</Text>
                <Flex justifyContent={"flex-start"}>
                  <Button
                    color={"white"}
                    background={"#D80000"}
                    height={"30px"}
                    mt={"50px"}
                  >
                    Update Settings
                  </Button>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyProfile;
