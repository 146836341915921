import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Navbar from "./Sidebar/Navbar";
import { useNavigate } from "react-router-dom";
import { FiEdit2, FiMoreVertical } from "react-icons/fi";
import { useState } from "react";
import { DeleteIcon, InfoIcon } from "@chakra-ui/icons";

const Users = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/adduser");
  };

  const handleEditUser = (userId) => {
    navigate(`/edituser`);
  };

  const [userData, setUserData] = useState([
    {
      id: 123,
      userId: "1234",
      name: "abcd",
      email: "abcd@gmail.com",
      reportingTo: "xyz",
      status: "Active",
    },
    {
      id: 124,
      userId: "1235",
      name: "efgh",
      email: "efgh@gmail.com",
      reportingTo: "uvw",
      status: "Active",
    },
    {
      id: 125,
      userId: "1236",
      name: "ijkl",
      email: "ijkl@gmail.com",
      reportingTo: "rst",
      status: "Active",
    },
    {
      id: 126,
      userId: "1237",
      name: "mnop",
      email: "mnop@gmail.com",
      reportingTo: "opq",
      status: "Active",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(2); 
  const totalPages = Math.ceil(userData.length / rowsPerPage);
  const currentUsers = userData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  const handleRowsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setRowsPerPage(selectedValue);
    setCurrentPage(1); 
  };
  const startEntry = (currentPage - 1) * rowsPerPage + 1;
  const endEntry = Math.min(currentPage * rowsPerPage, userData.length);

  return (
    <Box>
      <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="User" />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Flex p={3} mb={4}>
            <Heading
              color={"#151617"}
              as="h5"
              fontSize={"18px"}
              fontWeight={"bold"}
              fontFamily={"Public Sans, sans-serif"}
            >
              Users
            </Heading>
            <Box position={"absolute"} right={10}>
              <Flex>
                <Center>
                  <Checkbox mr={"10px"} mt={1} />
                </Center>{" "}
                <Text mr={"20px"} mt={1}>
                  {" "}
                  Show Only Active Users
                </Text>
                <Flex
                  fontSize={"14px"}
                  border={"1px solid red"}
                  lineHeight={7}
                  borderRadius={8}
                  fontFamily={"Public Sans, sans-serif"}
                  color={"#D80000"}
                >
                  <Text
                    borderRight={"1px solid #D80000"}
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}
                  >
                    Hierarchy Tree
                  </Text>
                  <Text
                    borderRight={"1px solid #D80000"}
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}
                  >
                    Bulk Upload
                  </Text>
                  <Text
                    pl={4}
                    pr={4}
                    cursor={"pointer"}
                    _hover={{ bg: "#D8000026" }}
                    onClick={handleOnClick}
                  >
                    Add New
                  </Text>
                </Flex>
              </Flex>
              <Flex mt={4} mb={4} justifyContent={"flex-end"}>
                <Text>Search</Text>
                <Input w={"40%"} height={8} ml={2} />
              </Flex>
            </Box>
          </Flex>
          <Flex ml={4} mt={4} justifyContent={"flex-start"}>
            <Text>Show</Text>
            <Select
              ml={2}
              width="70px"
              height={8}
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </Select>
            <Text ml={2}>entries</Text>
          </Flex>
          <TableContainer>
            <Table mt={4}>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th color={"white"}>SL No.</Th>
                  <Th color={"white"}>ID</Th>
                  <Th color={"white"}>User ID</Th>
                  <Th color={"white"}>Name</Th>
                  <Th color={"white"}>Email Address</Th>
                  <Th color={"white"}>Reporting To</Th>
                  <Th color={"white"}>Status</Th>
                  <Th color={"white"}>Action </Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentUsers.map((user, index) => (
                  <Tr key={user.id}>
                    <Td>{(currentPage - 1) * rowsPerPage + index + 1}</Td>
                    <Td>{user.id}</Td>
                    <Td>{user.userId}</Td>
                    <Td>{user.name}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.reportingTo}</Td>
                    <Td>
                      <Button
                        color={user.status === "Active" ? "green" : "red"}
                        height={8}
                      >
                        {user.status}
                      </Button>
                    </Td>
                    <Td>
                      <IconButton
                        icon={<FiEdit2 />}
                        height={8}
                        onClick={() => handleEditUser(user.userId)} // Navigate to edit user page
                      />
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<FiMoreVertical />}
                          ml={2}
                          height={8}
                          variant={"ghost"}
                        />
                        <MenuList minW="unset" width="150px">
                          <MenuItem height={"30px"} icon={<DeleteIcon />}>
                            Delete
                          </MenuItem>
                          <MenuItem height={"30px"} icon={<InfoIcon />}>
                            View Details
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex mt={1} ml={2}>
            <Text>
              {" "}
              Showing {startEntry} to {endEntry} of {userData.length} entries
            </Text>
          </Flex>
          <Flex justifyContent="flex-end" mt={4}>
            <Button
              onClick={handlePrevious}
              isDisabled={currentPage === 1}
              mr={2}
            >
              Previous
            </Button>
            <Button
              onClick={handleNext}
              isDisabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Users;
