import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Textarea,
  Button,
  useToast,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "./Sidebar/Navbar";
import { AddNewLeads } from "../Redux/AppReducer/Action";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import countryList from "react-select-country-list";
import { IoChevronDownOutline } from "react-icons/io5";

const AddNewLead = ({ isSidebarExpanded }) => {
  const options = countryList().getData();
  const [value, setValue] = React.useState(null);
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const [searchInput, setSearchInput] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedExecutive, setSelectedExecutive] = useState("");
  const leads = useSelector((state) => state.leads?.data);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    executive: "",
    alternateContact: "",
    designation: "",
    address: "",
    country: "",
    state: "",
    date: "",
    standard: "",
    totalNumberOfEmployees: "",
    services: "",
    companyWebsite: "",
  });

  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const checkFormValidity = () => {
    const {
      name,
      emailAddress,
      phoneNumber,
      executive,
      address,
      country,
      state,
      followUpDate,
      standard,
      totalNumberOfEmployees,
      services,
      companyWebsite,
    } = formData;

    if (
      !name ||
      !emailAddress ||
      !phoneNumber ||
      !executive ||
      !address ||
      !country ||
      !state ||
      !followUpDate ||
      !standard ||
      !totalNumberOfEmployees ||
      !services ||
      !companyWebsite
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };

  useEffect(() => {
    checkFormValidity();
  }, [formData]);

  const [submittedEmails, setSubmittedEmails] = useState([]);
  const handleSubmit = async () => {
    try {
      if (submittedEmails.includes(formData.emailAddress)) {
        toast({
          title: "Duplicate email.",
          description: "This email has already been submitted.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setSubmittedEmails([...submittedEmails, formData.emailAddress]);

      const updatedFormData = {
        ...formData,
        services: formData.services
          ?.split(",")
          .map((service) => service?.trim()),
      };

      const response = await dispatch(AddNewLeads(token, updatedFormData));
      console.log("Response:", response);

      setFormData({
        name: "",
        emailAddress: "",
        phoneNumber: "",
        executive: "",
        alternateContact: "",
        designation: "",
        address: "",
        country: "",
        state: "",
        followUpDate: "",
        standard: "",
        totalNumberOfEmployees: "",
        services: "",
        companyWebsite: "",
      });

      toast({
        title: "Lead added successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error adding lead.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#D80000" : "black",
      backgroundColor: state.isFocused ? "#D8000026" : "white",
    }),
  };

  const handleChange = (value) => {
    setValue(value);

    setFormData({
      ...formData,
      country: value.label,
    });
  };

  const executive = ["Name 1", "Name 2", "Name 3", "Name 4"];

  const filteredExecutive = executive.filter((executive) =>
    executive.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleExecutiveSelect = (executive) => {
    setSelectedExecutive(executive);
    setFormData({
      ...formData,
      executive: executive,
    });
    setSearchInput("");
  };

  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Add New Lead"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          p={3}
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your Name"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Email Address</FormLabel>
              <Input
                type="email"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleInputChange}
                placeholder="Enter your Email"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Phone Number</FormLabel>
              <Input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Enter your Phone Number"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Follow Up Date</FormLabel>
              <Input
                type="date"
                name="followUpDate"
                value={formData.followUpDate}
                onChange={handleInputChange}
                placeholder="Enter your Phone Number"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel fontSize={"0.9375rem"} lineHeight={"1.47"}>
                Executive:
              </FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoChevronDownOutline />}
                  width="100%"
                  textAlign="left"
                  color={"#b7b5be"}
                  background={"none"}
                  border={"1px solid #dbdade"}
                  _hover={{ bg: "none" }}
                >
                  {selectedExecutive ? selectedExecutive : "Executive"}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="auto">
                  <Box p={2}>
                    <Input
                      placeholder="Executive..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Box>
                  {filteredExecutive.length > 0 ? (
                    filteredExecutive.map((executive, index) => (
                      <MenuItem
                        _hover={{
                          bg: "#D8000026",
                          textColor: "#D80000",
                        }}
                        key={index}
                        onClick={() => handleExecutiveSelect(executive)}
                      >
                        {executive}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No matches found</MenuItem>
                  )}
                </MenuList>
              </Menu>
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Alternate Contact</FormLabel>
              <Input
                type="tel"
                name="alternateContact"
                value={formData.alternateContact}
                onChange={handleInputChange}
                placeholder="Enter Alternate Contact"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Designation</FormLabel>
              <Input
                type="text"
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
                placeholder="Enter the Designation"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Address</FormLabel>
              <Input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Enter Address"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Country:
              </FormLabel>
              <Select
                options={options}
                styles={customStyles}
                maxMenuHeight={"200px"}
                value={value}
                onChange={handleChange}
                placeholder="Country"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>State</FormLabel>
              <Input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                placeholder="Enter State"
              />
            </FormControl>

  

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Standard</FormLabel>
              <Input
                type="text"
                name="standard"
                value={formData.standard}
                onChange={handleInputChange}
                placeholder="Enter the standard"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Total no. of Employees</FormLabel>
              <Input
                type="number"
                name="totalNumberOfEmployees"
                value={formData.totalNumberOfEmployees}
                onChange={handleInputChange}
                placeholder="Enter number of Employees"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Services</FormLabel>
              <Textarea
                name="services"
                value={formData.services}
                onChange={handleInputChange}
                placeholder="Enter the services"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Company Website</FormLabel>
              <Input
                type="text"
                name="companyWebsite"
                value={formData.companyWebsite}
                onChange={handleInputChange}
                placeholder="Enter company website"
              />
            </FormControl>
          </Grid>

          <Button
            background={"#D80000"}
            color={"white"}
            height={8}
            position="absolute"
            bottom={4}
            right={8}
            onClick={handleSubmit}
            isDisabled={!isFormValid}
            _hover={{
              bg: "#D80000",
            }}
          >
            Add Lead
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddNewLead;
